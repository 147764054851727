import React from "react";
import PropTypes from "prop-types";
import { HiRefresh } from "react-icons/hi";
import DynamicForm from "../../../dynamic-form";
import Button from "../../../dynamic-form/elements/Button";
import DynamicTable from "../../../dynamic-page";
import Icons from "../../icons";

const BulkShipmentView = ({
  states,
  items,
  loading,
  errors,
  hideDelete,
  handleList,
  handleSort,
  myFormRef,
  formView,
  tableView,
  cancel,
  handleSubmit,
  refresh,
}) => (
  <>
    <div className="grid grid-cols-6 items-start gap-[8px] justify-center border-t border-t-gray-200 !px-[20px] pt-4 pb-[16px]">
      <DynamicForm
        items={formView(states)}
        ref={myFormRef}
        onChange={(value, bound) => handleList(value, bound)}
      />
    </div>
    <div className="px-5">
      <DynamicTable
        view={tableView}
        data={tableView(
          items.map((item) => ({
            ...item,
            _loading: loading[item.id],
            _error: errors[item.id],
          })),
          states
        )}
        selectedRow={[]}
        loading="idle"
        style={{
          outerContainer: "flex-1",
          hiddenActions: true,
          hideSelect: true,
          table: {
            style: {
              width: "100%",
              minWidth: "100%",
              height: "500px",
              zIndex: 0,
            },
            className: "overflow-auto",
          },
          header: {
            className: "!justify-center !py-4",
            delete: {
              className: "hidden",
              th: hideDelete ? "hidden" : "",
            },
            cost: { th: hideDelete ? "hidden" : "" },
            _actions: { td: "hidden", className: "hidden" },
            image: { className: "hidden" },
            status: { className: "hidden" },
            control: { className: "hidden" },
          },
          row: {
            className:
              "!px-[8px] !py-[10px] !items-center !justify-center flex text-[12px] flex flex-1 h-[54px]",
            _actions: { className: "!py-[0px] hidden" },
            delete: {
              className: "!py-[0px]",
              td: hideDelete ? "hidden" : "",
            },
            item: {
              className: "!py-0",
            },
            qty: {
              icon: (
                <Icons.TwoArrowSort
                  className="text-gray-300"
                  onClick={() => handleSort("items_total_qty")}
                />
              ),
            },
            weight: {
              icon: (
                <Icons.TwoArrowSort
                  className="text-gray-300"
                  onClick={() => handleSort("billable_weight")}
                />
              ),
            },
            cost: {
              className: "!p-0",
              td: hideDelete ? "hidden" : "",
            },
            carrier: {
              className: "!w-[240px]",
            },
          },
          noData: {
            className: "hidden",
          },
        }}
      />
    </div>
    <div className="flex w-full justify-between items-center px-[20px] pb-[8px] pt-[16px]">
      <div className="text-[16px] font-medium font-['Inter'] leading-[24px] text-gray-500">
        {items.length} orders &{" "}
        {items?.reduce((acc, item) => acc + item?.items_total_qty, 0)} total
        quantity
      </div>
      {items?.some((item) => item?.shipment_labels?.length > 0) ? (
        <Button
          item={{
            label: <div className={"flex flex-row"}>Completed</div>,
            buttonType: "alt",
            className: "h-[41px] !w-[120px] !px-[16px]",
          }}
          onClick={() => {
            refresh();
            cancel();
          }}
        />
      ) : (
        <div className="flex flex-row gap-[8px]">
          <Button
            item={{
              label: <div className={"flex flex-row"}>Cancel</div>,
              buttonType: "alt",
              className: "h-[41px] !w-[120px] !px-[16px]",
            }}
            onClick={cancel}
          />
          <Button
            item={{
              label: (
                <div className={"flex flex-row"}>
                  {Object.keys(loading).some((key) => loading[key]) ? (
                    <div className="flex flex-row gap-[8px]">
                      <HiRefresh className="animate-spin h-5 w-5" />
                      <span className="text-sm font-medium font-['Inter'] leading-[21px]">
                        Creating...
                      </span>
                    </div>
                  ) : (
                    "Create Shipment"
                  )}
                </div>
              ),
              buttonType: "primary",
              className: "h-[41px] !px-[16px]",
              disabled: Object.keys(loading).some((key) => loading[key]),
            }}
            onClick={handleSubmit}
          />
        </div>
      )}
    </div>
  </>
);

BulkShipmentView.propTypes = {
  states: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  hideDelete: PropTypes.bool.isRequired,
  handleList: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  myFormRef: PropTypes.object.isRequired,
  formView: PropTypes.func.isRequired,
  tableView: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default BulkShipmentView;

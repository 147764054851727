import { useState } from "react";
import Icons from "../../orders/icons";
import { useForm } from "react-hook-form";
import ProductDeleteModal from "../../product/Product-delete-modal";
import PropTypes from "prop-types";
import PopupUp from "../../dynamic-page/util/popUp";

const DirtySelect = ({
  isValue,
  texts,
  type,
  tooltipPosition,
  placeholder,
  attributeId,
  className,
  titleClassName,
  textArea,
  title,
  handleValueClicked,
  titleDesc,
}) => {
  const hasValue = isValue;
  const isProductName = title === "Product Name";

  const getBorderColor = () => {
    if (isFocused && !errors.value) return "border-blue-600";
    if (isProductName && errors.value) return "border-red-500";
    return "border-gray-300";
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      value: hasValue ? hasValue?.value || hasValue : "",
    },
    mode: "onChange",
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const onSubmit = async (data) => {
    await handleValueClicked({
      reset,
      attributeId,
      value: data?.value,
      setDeleteModal: !isProductName && setDeleteModal,
    });
  };

  const Check = Icons.chechkCircle;
  const Close = Icons.close;
  const Info = Icons.info;

  return (
    <div className={`flex flex-col gap-2 rounded-[8px] ${className} `}>
      <form
        onSubmit={
          watch("value")
            ? handleSubmit(onSubmit)
            : (e) => {
                e.preventDefault();
                isProductName ? handleSubmit(onSubmit)() : setDeleteModal(true);
              }
        }
        className="flex justify-between items-center"
      >
        <div className="flex gap-1 items-center">
          <label
            className={`text-[14px] leading-[21px] font-medium text-gray-900 ${titleClassName}`}
          >
            {title}
            {titleDesc && (
              <span className="font-semibold text-gray-500 text-[12px] ml-1 leading-normal">
                {titleDesc}
              </span>
            )}
          </label>
          {texts && (
            <PopupUp
              toggle={() => (
                <Info className="w-5 h-5 text-gray-300 hover:text-gray-500" />
              )}
              tabIndex={-1}
              action="hover"
              portal={true}
              forcePosition={tooltipPosition}
            >
              <div className="flex flex-col gap-1.5 p-1.5 px-2 w-[297px]">
                <span className="text-[14px] leading-[14px] font-medium text-gray-900">
                  {texts?.tooltipTitle}
                </span>
                <span className="text-gray-500 text-[12px] leading-[15px] font-normal">
                  {texts?.tooltipDesc}
                </span>
              </div>
            </PopupUp>
          )}
        </div>
        {isDirty && (
          <button
            type="submit"
            className="text-blue-700 hover:text-blue-500 text-[14px] leading-normal font-medium"
          >
            <Check className="w-5 h-5" />
          </button>
        )}
      </form>
      <div
        className={`flex justify-between ${getBorderColor()} bg-gray-50 border rounded-[8px] pr-4 ${
          !textArea && "items-center"
        }`}
      >
        {!textArea ? (
          <input
            className="bg-gray-50  rounded-lg w-full border-none [&::-webkit-inner-spin-button]:appearance-none"
            placeholder={placeholder}
            type={type || "text"}
            {...register("value", {
              ...(isProductName && {
                required: "Product name is required",
                minLength: {
                  value: 3,
                  message: "Product name must be at least 3 characters",
                },
                maxLength: {
                  value: 100,
                  message: "Product name must not exceed 100 characters",
                },
              }),
            })}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
            onBlur={handleBlur}
          />
        ) : (
          <textarea
            {...register("value")}
            className="border w-full resize-none border-none h-[96px] bg-gray-50 rounded-[8px] px-4 py-2"
            placeholder={placeholder}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
            onBlur={handleBlur}
          />
        )}
        {watch("value") && (
          <Close
            onClick={() => setValue("value", "", { shouldDirty: true })}
            className={`w-[18px] h-[18px] ${
              textArea && "mt-2"
            } cursor-pointer text-gray-500`}
          />
        )}
      </div>
      {isProductName && errors.value && (
        <span className="text-red-500 text-sm mt-1">
          {errors.value.message}
        </span>
      )}
      {!isProductName && deleteModal && (
        <ProductDeleteModal
          name={"the attribute"}
          show={isDirty}
          onClose={() => setDeleteModal(false)}
          handleProductDeletion={handleSubmit(onSubmit)}
        />
      )}
    </div>
  );
};
DirtySelect.propTypes = {
  isValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  texts: PropTypes.shape({
    tooltipTitle: PropTypes.string,
    tooltipDesc: PropTypes.string,
  }),
  type: PropTypes.string,
  tooltipPosition: PropTypes.string,
  placeholder: PropTypes.string,
  attributeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  textArea: PropTypes.bool,
  title: PropTypes.string.isRequired,
  handleValueClicked: PropTypes.func.isRequired,
  titleDesc: PropTypes.string,
};

export default DirtySelect;

import React, { useEffect, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import selectedItem from "./select-util/selectedItem";
import fetchOptions from "./select-util/fetchOptions";
import returnValue from "./select-util/returnValue";
import style from "./select-util/style";
import checkUrl from "./select-util/checkUrls";
import { addNewAction, addNewView } from "./select-util/addNew";
import debounce from "./select-util/debounce";
import PropTypes from "prop-types";

const SelectComponent = ({
  item,
  value,
  validationMessage,
  handleChange,
  bind,
  formData,
  ...rest
}) => {
  const SelectComponent = item?.addNew ? CreatableSelect : Select;

  const [loadedOptions, setLoadedOptions] = React.useState([]);
  const [innerValue, setInnerValue] = React.useState(
    item?.defaultBound
      ? {
          label: item?.defaultBound?.name,
          value: item?.defaultBound?.id,
        }
      : null
  );
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [innerFormData, setInnerFormData] = React.useState(formData);

  const selectRef = useRef(null);
  const valueRef = useRef(null);

  const loadOptions = debounce((inputValue, first) => {
    if (
      item?.features?.isMulti &&
      typeof value === "string" &&
      value?.split(",").length > 1
    ) {
      value = value.split(",");
    }
    if (
      item?.features?.isMulti &&
      typeof item?.defaultValue === "string" &&
      item?.defaultValue?.split(",").length > 1
    ) {
      item.defaultValue = item.defaultValue.split(",");
    }
    if (item.api && (!loaded || (inputValue != null && !first)) && !loading) {
      setLoading(true);
      fetchOptions(
        item.api,
        inputValue,
        first ? value : null,
        first ? item.defaultValue : null,
        item?.list,
        formData
      ).then((data) => {
        setLoading(false);
        setLoaded(!item?.autoLoad);
        if (data.length === 0 && !inputValue) {
          item?.notify(
            "No carrier found that can be activated for this provider",
            "info"
          );
        }
        setLoadedOptions([...(item?.api?.list || []), ...data]);
      });
    } else if (item.list && !item.api) {
      setLoadedOptions(item.list);
    }
  }, 500);

  const selectOption = () => {
    const selected = selectedItem(
      value,
      loadedOptions,
      innerValue,
      item?.features?.isMulti
    );
    if (bind) bind(selected, item.name);
    return selected;
  };

  useEffect(() => {
    const check = checkUrl(
      item.api,
      formData,
      innerFormData,
      value,
      loadedOptions,
      loading
    );
    setIsDisabled(check.disable);
    !loading && setInnerValue(selectOption());

    if (check.reset) {
      setLoaded(false);
    }
    if (check.related) {
      if (check.load) {
        loadOptions("", !item?.defaultBound);
      }
    } else if (value && !item?.defaultBound) {
      loadOptions("", true);
    }
    if (value === "") {
      selectRef.current.setValue(null);
    }
  }, [value, loadedOptions, loading]);

  useEffect(() => {
    if (
      loadedOptions.length === 0 &&
      !item?.disablePreload &&
      !item?.defaultValue
    ) {
      loadOptions("", !item?.defaultBound);
    }
  }, []);

  useEffect(() => {
    const check = checkUrl(
      item.api,
      formData,
      innerFormData,
      value,
      loadedOptions,
      loading
    );
    if (check.reset) {
      setLoaded(false);
    }
    setInnerFormData(formData);
    setIsDisabled(check.disable);

    if (check.reset) {
      setLoadedOptions([]);
      setInnerValue(null);
      setTimeout(() => {
        handleChange(null, item.name, null);
      }, 100);
    }
  }, [formData]);

  // Custom filter function for react-select
  const customFilterOption = (option, inputValue) => {
    const normalizeString = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    const label = normalizeString(
      typeof option.label === "object" ? option.value : option.label
    );
    const apid = normalizeString(option?.data?.data?.apid || "");
    const input = normalizeString(inputValue);
    if (item?.execludedValues?.(formData)?.includes(option?.value)) {
      return false;
    }
    return label.includes(input) || apid.includes(input);
  };

  return (
    <div
      className={`${
        item.innerClassName || ""
      } dynamicForm relative flex items-center`}
    >
      <style>
        {`
          .dynamicForm .css-1f43avz-a11yText-A11yText,
          .css-b62m3t-container {
              width: 100%;
          }
          .dynamicForm .css-1y6o3d0-menu {width:100%}
          .search-icon {
            position: absolute;
            left: 12px;
            z-index: 1;
            color: #8c8c8c;
          }
          .select-with-icon .css-13cymwt-control {
            padding-left: 28px;
          }
        `}
      </style>
      {item.searchIcon && (
        <svg
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="15px"
          height="15px"
          fill="none"
        >
          <g clipPath="url(#a)">
            <path
              stroke="#6B7280"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m10.5 10.5-3-3m1-2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h12v12H0z" />
            </clipPath>
          </defs>
        </svg>
      )}
      <SelectComponent
        ref={selectRef}
        onInputChange={(changeValue) => {
          loadOptions(changeValue, false);
        }}
        isLoading={loading}
        options={loadedOptions}
        filterOption={customFilterOption}
        value={innerValue}
        formatCreateLabel={addNewView}
        onCreateOption={(newOption) => {
          addNewAction(newOption, item, formData, loadOptions);
        }}
        defaultValue={null}
        onChange={(newData) => {
          setInnerValue(newData);
          valueRef.current = newData;
          handleChange(returnValue(newData), item.name, newData);
          if (item?.instantSubmit) {
            handleChange(null, item.name, null);
          }
        }}
        className={`h-full !min-w-full block ${
          item.innerClassName2 || ""
        } !bg-transparent ${item.searchIcon ? "select-with-icon" : ""}`}
        isClearable={false}
        isDisabled={
          isDisabled ||
          (item?.disabled && typeof item?.disabled === "function"
            ? item?.disabled(formData)
            : item?.disabled)
        }
        placeholder={item.placeholder}
        menuPlacement={item.menuPlacement || "auto"}
        onMenuOpen={() => {
          if (!item?.features?.isMulti) {
            valueRef.current = innerValue;
            setInnerValue(null);
          }
        }}
        menuPortalTarget={document.body}
        onMenuClose={() => {
          if (!item?.features?.isMulti) {
            if (!item?.instantSubmit) {
              setInnerValue(valueRef.current);
            }
          }
        }}
        styles={style({
          validation: validationMessage,
          style: item.style || {},
        })}
        {...item.features}
      />
    </div>
  );
};
SelectComponent.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.any, // Adjust the type based on the expected structure
  validationMessage: PropTypes.string, // Add validation for validationMessage
  handleChange: PropTypes.func.isRequired,
  bind: PropTypes.func,
  formData: PropTypes.object,
  rest: PropTypes.object, // Adjust this based on additional props
};

// Define defaultProps if needed
SelectComponent.defaultProps = {
  validationMessage: "", // Default value for validationMessage
  bind: null,
  formData: {},
};

export default SelectComponent;

import React from "react";
import PropTypes from "prop-types";
import PopupUp from "../util/popUp";

const Button = ({ item, index, selected, list }) => (
  <div
    className={`
              w-[32px] h-[32px] bg-gray-100 items-center justify-center flex rounded-full  relative text-gray-500 selected-${selected}
              ${
                item.status === "completed"
                  ? " bg-green-100 text-green-600 completedStatus"
                  : ""
              }
              ${
                item.status === "missing"
                  ? " bg-yellow-100 text-yellow-600 missingStatus"
                  : ""
              }
              ${
                item.action &&
                item.status === "awaiting" &&
                (list[index - 1]?.status === "completed" || index === 0)
                  ? " buttonStatus cursor-pointer bg-blue-100 text-blue-700 border-2 border-blue-700 hover:bg-blue-700 hover:text-white transition"
                  : ""
              }
          `}
  >
    {item?.label}
  </div>
);

Button.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
};

const StatusSteps = ({ list, tooltip }) => {
  const RenderList = () =>
    list.map((item, index) => (
      <Button key={item?.label} item={item} index={index} list={list} />
    ));

  return (
    <>
      <style>
        {`
                    .StatusSteps {
                        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0 2px 1px rgba(0, 0, 0, 0.04));

                    }
                    .StatusSteps:has(.popOver-true) {
                        z-index: 2;
                    }
                    .StatusSteps > div:not(:first-child)::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -24px !important;
                        transform: translateY(-50%);
                        display: block;
                        width: 24px;
                        height: 6px;
                        background: #F3F4F6;
                    }
                    .StatusSteps > div:has(.completedStatus):not(:first-child)::after {
                        background: #DEF7EC;
                    }
                    .StatusSteps > div:has(.missingStatus):not(:first-child)::after {
                        background: #FDF6B2;
                    }
                    .StatusSteps > div:has(.buttonStatus):not(:first-child)::after {
                        background: #E1EFFE;
                    }
                  
                `}
      </style>
      <PopupUp
        toggle={(selected) => (
          <div className={"flex flex-row px-2 gap-[24px] StatusSteps"}>
            <RenderList />
          </div>
        )}
        action={"click"}
        delay={700}
      >
        {/* {item?.title}esdee */}
        {tooltip}
      </PopupUp>
    </>
  );
};

StatusSteps.propTypes = {
  list: PropTypes.array.isRequired,
  tooltip: PropTypes.node,
};

export default StatusSteps;

import React from "react";
import util from "../../dynamic-page/util";
import moment from "moment/moment";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import { HiArrowCircleDown, HiArrowCircleUp } from "react-icons/hi";
import quantityHandler from "../../../utils/quantityHandler";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import { FaWarehouse } from "react-icons/fa";
import ItemID from "../../dynamic-page/components/ItemID";
import CopyText from "../../dynamic-page/util/copyText";
import TruncateText from "../../dynamic-page/util/truncateText";

const tableView = (list, callBack) => {
  const nullHandle = (data, chained) => {
    if (!data || !chained) {
      return "";
    } else {
      return chained;
    }
  };

  const badgeIcon = (item) => {
    let icon;
    if (item?.type === "in") {
      icon = <HiArrowCircleDown className="w-4 h-4 text-green-500" />;
    } else if (item?.type === "reserved") {
      icon = <FaWarehouse className="w-3 h-3 text-yellow-500" />;
    } else {
      icon = <HiArrowCircleUp className="w-4 h-4 text-red-500" />;
    }
    return icon;
  };

  return {
    list: list ?? [],
    theme: {
      "adjustment id": (item) => <ItemID id={item.id} />,
      "created at": (item) => {
        return <util.dateDisplay item={item} date={item?.created_at} />;
      },
      image: (item) => (
        <div
          className={
            "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
          }
        >
          {item?.product?.image ? (
            <ImageMagnifier
              src={item?.product?.image}
              className={"!max-h-[64px] w-[100%]"}
            />
          ) : (
            <img
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),
      product: (item) => (
        <div className="flex flex-col whitespace-pre-wrap items-center justify-center gap-y-[10px] w-[100%]">
          {item?.product ? (
            <TruncateText maxLines={1} className={"text-[14px]"} tooltip={true}>
              {item?.product?.title}
            </TruncateText>
          ) : (
            "-"
          )}

          <div className={"flex justify-between w-full items-center"}>
            {item?.product?.brand?.name && (
              <div className="flex gap-1 items-center">
                <span className="text-[12px] font-medium  text-gray-500 leading-[14px]">
                  {item?.product?.brand.name}
                </span>
                <CopyText
                  className={"text-[9px]"}
                  text={item?.product?.brand.name}
                  hint={false}
                />
              </div>
            )}
            {item?.product ? (
              <div className="flex items-center gap-[2px] !text-[12px]">
                <button
                  type="button"
                  className={
                    "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
                  }
                  onClick={() => {
                    window.open(`/product/${item?.product?.id}`, "_blank");
                  }}
                >
                  {item?.product?.brand?.assigned ? (
                    <>
                      <img
                        src={
                          item?.product?.brand?.assigned?.image_url ||
                          "/assets/images/defaultAvatar.png"
                        }
                        className={
                          "w-[20px] h-[20px] rounded-full object-cover"
                        }
                      />
                      {util.upperCaseString(
                        item?.product?.brand?.assigned?.name,
                        "word"
                      )}
                    </>
                  ) : (
                    <div className="text-gray-400">Unassigned</div>
                  )}
                </button>
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      ),
      qty: (item) => (
        <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
          {quantityHandler(item?.qty, item?.product?.unit)}
        </div>
      ),
      warehouse: (item) => (
        <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
          {nullHandle(item?.warehouse, item?.warehouse?.name)}
        </div>
      ),
      type: (item) => (
        <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
          <ArbitNormalBadge
            title={(() => {
              if (item?.type === "in") return "IN";
              if (item?.type === "reserved") return "Reserved";
              return "OUT";
            })()}
            icon={badgeIcon(item)}
            bgColor={() => {
              let bgColor = "red-100";
              if (item?.type === "in") {
                bgColor = "green-100";
              } else if (item?.type === "reserved") {
                bgColor = "yellow-100";
              }
              return bgColor;
            }}
          />
        </div>
      ),
      "created by": (item) => (
        <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
          {nullHandle(item?.created_by, item?.created_by?.name)}
        </div>
      ),
      "expiry date": (item) => (
        <div className="flex-col ">
          {/* display 3 divs to show labels and prices for : subtotal total due and total */}
          <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-cemter text-center">
            <p className="text-gray-900 text-[16px] font-[400]">
              {item?.good_acceptance?.expiry_date
                ? moment(item?.good_acceptance?.expiry_date).format(
                    "DD/MM/YYYY"
                  )
                : "-"}
            </p>
          </div>
        </div>
      ),
      reason: (item) => (
        <div className="flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center">
          {nullHandle(item, item?.reason)}
        </div>
      ),
    },
  };
};
export default tableView;

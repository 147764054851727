import React, { useEffect } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";
import style from "../../../dynamic-form/elements/select-util/style";

const CarrerSelect = ({ item, submit, disabled }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);

  useEffect(() => {
    const fetchCarriers = async () => {
      if (item?.warehouse?.id || item?.onWarehouse?.id) {
        const fromStateId = item?.onWarehouse?.state?.id || item?.warehouse?.state?.id;
        setUpdate(true);
        let url =
          "api/v1/logistics/calculator" +
          `?from_state_id=${fromStateId}&to_state_id=${
            item?.state
          }&billable_weight=${item?.billable_weight}&fresh=${item?.fresh || 0}`;
        const response = await axios.get(url, {
          withCredentials: true,
          skipCancel: true,
        });
        const rs = response?.data;
        setCarriers(rs?.data);
        if (rs?.data && rs.data.length > 0) {
          const firstCarrier = {
            label: rs.data[0].carrier?.name,
            value: rs.data[0].id,
            cost: rs.data[0].cost,
            code: rs.data[0].service_code,
          };
          setSelectedItem(firstCarrier);
          submit({
            carrierId: firstCarrier.value,
            cost: firstCarrier.cost,
            code: firstCarrier.code,
          });
        }
        setUpdate(false);
      }
    };

    fetchCarriers();
  }, [
    item?.warehouse?.id,
    item?.fromStateId,
    item?.onWarehouse?.id,
    item?.orderId,
  ]);

  const handleSelectedItem = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null);
      submit({ carrierId: null, cost: null });
    } else {
      setSelectedItem(item);
      submit({ carrierId: item.value, cost: item.cost, code: item.code });
    }
  };

  const options = carriers.map((carrier) => ({
    label: carrier.carrier?.name,
    value: carrier.id,
    cost: carrier.cost,
    code: carrier.service_code,
  }));

  return (
    <>
      {update ? (
        <div className="flex justify-center items-center">
          <div className="w-10 h-10 border-t-transparent border-solid animate-spin rounded-full border-blue-500 border-8"></div>
        </div>
      ) : (
        <Select
          options={options}
          value={selectedItem}
          onChange={handleSelectedItem}
          placeholder="Select Carrier"
          isDisabled={disabled}
          styles={style({
            style: {
              control: {
                height: "42px !important",
                minHeight: "auto",
                width: "200px !important",
                minWidth: "200px !important",
                background: "#F9FAFB",
              },
              menu: {
                whiteSpace: "wrap !important",
              },
              valueContainer: {
                width: "174px !important",
                minWidth: "174px !important",
              },
              singleValue: {
                width: "154px !important",
                fontSize: "14px !important",
                minWidth: "154px !important",
              },
            },
          })}
        />
      )}
    </>
  );
};

CarrerSelect.propTypes = {
  item: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CarrerSelect;

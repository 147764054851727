import React, { useState } from "react";
import { HiRefresh } from "react-icons/hi";
import axios from "axios";
import PropTypes from "prop-types";
const CarrierCost = ({ item, states, onUpdateCarrier }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    if (isRefreshing || !states?.warehouse?.id || !states?.fromStateId) return;

    setIsRefreshing(true);
    try {

      const url = `api/v1/logistics/calculator?from_state_id=${states.fromStateId}&to_state_id=${item?.ship_to_state?.id}&billable_weight=${item?.billable_weight}&fresh=1`;

      const response = await axios.get(url, {
        withCredentials: true,
        skipCancel: true,
      });

      const carriers = response?.data?.data;
        if (carriers && carriers.length > 0) {
          console.log(states)
        // Find the carrier with matching service_code in the new data
        const selectedCarrier = carriers.find(
          (carrier) => carrier.service_code === item.code
        );
          console.log(selectedCarrier)

        if (selectedCarrier) {
          onUpdateCarrier(item.id, {
            carrierId: selectedCarrier.id, // This will be the new ID
            cost: selectedCarrier.cost,
          });
        }
      }
    } catch (error) {
      console.error("Error refreshing carrier cost:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  if (isRefreshing) {
    return (
      <div className="bg-gray-100 flex w-[112px] h-full items-center gap-2 p-2">
        <HiRefresh className="h-4 w-4 animate-spin text-blue-500" />
        <span className="text-gray-500">Getting...</span>
      </div>
    );
  }

  return (
      <div className="flex flex-col p-2 gap-[4px] relative  w-[112px]">
      <div className="text-[16px] font-medium text-gray-900 !leading-[24px] flex items-center gap-2">
        {item?.selectedCarrierCost ? `$${item.selectedCarrierCost}` : "-"}
      </div>
      {item.selectedCarrierId && (
        <button
          type="button"
          className="text-gray-400 cursor-pointer hover:text-gray-600"
          onClick={handleRefresh}
        >
          <HiRefresh className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};
CarrierCost.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectedCarrierId: PropTypes.string,
    selectedCarrierCost: PropTypes.number,
    code: PropTypes.string.isRequired,
    billable_weight: PropTypes.number.isRequired,
    ship_to_state: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  states: PropTypes.shape({
    warehouse: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    fromStateId: PropTypes.string.isRequired,
  }),
  onUpdateCarrier: PropTypes.func.isRequired,
};
export default CarrierCost;

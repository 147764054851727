import React from "react";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import Sku from "../components/sku";
import Items from "../components/item";
import Numbers from "../components/numbers";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import util from "../../dynamic-page/util";
import PopupUp from "../../dynamic-page/util/popUp";
import { HiInformationCircle } from "react-icons/hi";

const TableView = (list, openListingModal, onFilterApply, pageData) => {
  const quantityTooltipList = (item) => {
    if (!item) {
      return [];
    }
    return [
      {
        title: "Available",
        value:
          item?.available_quantity != null ? item?.available_quantity : "-",
      },
      {
        title: "Reserved",
        value: item?.reserved_quantity != null ? item?.reserved_quantity : "-",
      },
      {
        title: "Inbound",
        value: item?.inbound_quantity != null ? item?.inbound_quantity : "-",
      },
      {
        title: "Unfulfillable",
        value:
          item?.unfulfillable_quantity != null
            ? item?.unfulfillable_quantity
            : "-",
      },
    ];
  };

  const agedCostTooltip = (item) => {
    if (!item) {
      return [];
    }

    let result = [];

    for (let key in item) {
      let title = key
        .replace("estimated_ais_", "")
        .replace("_", " - ")
        .replace("_days", "")
        .replace("- plus", "+");
      result.push({
        title: title,
        value: "$" + item[key],
      });
    }

    return result;
  };

  const handleFulfilmentTextColor = (fulfillment) => {
    if (fulfillment === "FBM") {
      return "text-blue-700";
    } else if (fulfillment === "FBA") {
      return "text-orange-700";
    } else {
      return "text-[#6B7280]";
    }
  };

  return {
    list: list,
    theme: {
      type: (item) => {
        let bgColor;

        if (item.fulfilment === "FBM") {
          bgColor = "blue-100";
        } else if (item.fulfilment === "FBA") {
          bgColor = "red-50";
        } else {
          bgColor = "gray-100";
        }

        return (
          <div className="flex-col justify-center items-center gap-1 inline-flex">
            <ArbitNormalBadge
              title={item.fulfilment}
              bgColor={bgColor}
              className={"!px-[12px] !h-[28px]"}
              textColor={item.fulfilment === "FBM" ? "blue-800" : "red-500"}
            />
          </div>
        );
      },
      "updated at": (item) => (
        <util.dateDisplay item={item} date={item?.updated_at} />
      ),

      listing: (item) => (
        <div className={"flex flex-col gap-[0px]  justify-start  w-full"}>
          <Sku
            callBack={openListingModal}
            orderId={item.sku}
            order={{
              order_number: item?.identifier,
              listingUrl: item?.url,
              country: item?.account?.channel?.country,
              account: item?.account?.name,
              ...item?.account?.channel,
            }}
            offer={item}
          />
        </div>
      ),

      image: (item) => (
        <div
          className={
            "w-[52px] h-[52px] max-h-[52px] justify-center items-center flex"
          }
        >
          {item?.image ? (
            <ImageMagnifier src={item?.image} className={"!max-h-[52px]"} />
          ) : (
            <img
              alt="def"
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),

      offer: (item) => {
        return (
          <div className="!w-full">
            <Items
              product={[item?.product]}
              orderId={item?.id}
              offer={item}
              openListingModal={openListingModal}
              onFilterApply={onFilterApply}
              pageData={pageData}
            />
          </div>
        );
      },

      "Price & Quantity": (item) => (
        <div className="flex flex-col gap-1 items-end justify-end">
          <span
            className={`${handleFulfilmentTextColor(
              item?.fulfilment
            )}  text-[18px] leading-[18px] font-bold self-end `}
          >
            ${priceHandler(item?.price)}
          </span>
          <div className="flex items-center gap-1">
            <span
              className={`${handleFulfilmentTextColor(
                item?.fulfilment
              )}  text-[14px] leading-[14px] font-medium self-end `}
            >
              {item?.quantity || "-"}
            </span>
            <span>
              <PopupUp
                toggle={(selected) => (
                  <HiInformationCircle
                    className={
                      "text-gray-300 h-3 w-3 hover:text-gray-500 text-[18px]"
                    }
                  />
                )}
                action={"hover"}
              >
                <div
                  className={"px-[8px] w-[213px] py-[4px] flex flex-col gap-1"}
                >
                  {item?.fulfilment === "FBA" ? (
                    quantityTooltipList(item?.fba_quantity_tooltip).map(
                      (item, index) => (
                        <div
                          key={item?.value}
                          className={"flex  justify-between items-ceter"}
                        >
                          <div
                            className={
                              " text-gray-900 text-sm font-normal leading-[21px]"
                            }
                          >
                            {item?.title}
                          </div>
                          <div
                            className={
                              "text-gray-900 text-sm font-bold leading-[21px]"
                            }
                          >
                            {item?.value}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className={"flex  justify-between items-ceter"}>
                      <div
                        className={
                          " text-gray-900 text-sm font-normal leading-[21px]"
                        }
                      >
                        Available
                      </div>
                      <div
                        className={
                          "text-gray-900 text-sm font-bold leading-[21px]"
                        }
                      >
                        {item?.available_quantity || "-"}
                      </div>
                    </div>
                  )}
                </div>
              </PopupUp>
            </span>
          </div>
        </div>
      ),

      "est. aged inv. cost": (item) => (
        <Numbers
          data={item?.estimated_aged_inventory_cost}
          processFunction={priceHandler}
          currency={{ symbol: "$", position: 1 }}
          list={agedCostTooltip(item?.aged_inventory_cost_tooltip)}
          color={'text-[#C81E1E]'}
        />
      ),
      "est. STORAGE COST": (item) => (
        <Numbers
          data={item?.estimated_storage_cost_next_month}
          processFunction={priceHandler}
          currency={{ symbol: "$", position: 1 }}
          color={'text-[#C81E1E]'}
        />
      ),
    },
  };
};
export default TableView;

import {Document, Page} from "react-pdf";
import React, {useState} from "react";
import {AiOutlineCloudDownload, AiOutlineExpand, AiOutlinePrinter} from "react-icons/ai";
import Box from "./Box";
import util from "../util";
import PropTypes from "prop-types";
import Button from "../../dynamic-form/elements/Button";

const Pdf = ({url}) => {
    const filesUrl = (!Array.isArray(url)) ? [url] : url;


    const [sizeStyle, setSizeStyle] = useState(false);


    return (
        <Box

            options={(
                <div className={"flex flex-row gap-[4px]"}>
                    <Button
                        item={{
                            ButtonType: "alt",
                            className: `!p-[8px] ${sizeStyle ? " !bg-blue-500 hover:!bg-blue-600 text-white" : ""}`,
                        }}
                        onClick={() => {
                            setSizeStyle(!sizeStyle)
                        }}
                    >
                        <AiOutlineExpand className={"w-[17px] h-[17px]"}/>
                    </Button>
                    <Button
                        item={{
                            ButtonType: "alt",
                            className: "!p-[8px]",
                        }}
                        onClick={() => {
                            util.pdfViewer(url, true, `Shipment Label`)
                        }}
                    >
                        <AiOutlinePrinter className={"w-[17px] h-[17px]"}/>
                    </Button>
                    <Button
                        item={{
                            ButtonType: "alt",
                            className: "!p-[8px]",
                        }}
                        onClick={() => window.open(url, "_blank")}
                    >
                        <AiOutlineCloudDownload className={"w-[17px] h-[17px]"}/>
                    </Button>

                </div>
            )}
        >
            <div className={"w-[100%]"} style={{height: "calc(100vh - 350px)", width: "calc(100vw - 80px)"}}>
                <style>
                    {`
                    #root {height: 100%;}
                    .react-pdf__Page__textContent, .react-pdf__Page__annotations {display: none !important;}
                    .react-pdf__Page__svg {
                        width: ${sizeStyle ? "100%" : "auto"} !important;
                        height: ${sizeStyle ? "auto" : "calc(100% - 24px)"} !important;
                    }
                    .react-pdf__Page__svg > svg{
                        width: ${sizeStyle ? "100%" : "auto"} !important;
                        height: ${sizeStyle ? "auto" : "calc(100% - 24px)"} !important;
                    }
  
                    div.react-pdf__Page__svg {
                        width: ${sizeStyle ? "100%" : "auto"} !important;
                        height: ${sizeStyle ? "auto" : "calc(100% - 24px)"} !important;
                        border-radius: 8px !important;
                        border: 1px solid #e5e7eb;
                        margin: 12px;
                        padding: 12px;
                        overflow: auto !important;
                    }
                    .D_BoxContainer {position: relative;}
                    .D_BoxHeader {
                        position: absolute; top:-7px; right:-10px; z-index: 1000;
                    }
                `}
                </style>
                {filesUrl.map((item, index) => (
                    <img
                    src={item}
                        key={`document_${index + 1}`}
                        alt={`document_${index + 1}`}
                />
                ))}


            </div>

        </Box>


    )
}

Pdf.propTypes = {
    url: PropTypes.string.isRequired
};

const PdfFile = ({url}) => {
    const [totalPages, setTotalPages] = useState(0);

    function onDocumentLoadSuccess({numPages}) {
        setTotalPages(numPages);
    }

    return (
        <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"w-full h-full"}
        >
            {Array.from(new Array(totalPages), (el, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}

                    className={"w-full h-full justify-center flex flex-row "}
                    renderMode="svg" // Render pages as SVG images
                >

                </Page>
            ))}
        </Document>
    )
}

PdfFile.propTypes = {
    url: PropTypes.string.isRequired
};

export default Pdf;
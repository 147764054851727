import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { updateRow, fetchSalesListing } from "../../../store/fc-restocking";
import { useDispatch } from "react-redux";
import { HiFilter } from "react-icons/hi";
import PropTypes from "prop-types";
import ConnectForm from "../../sales-listing/modals-view/form";
import descriptiveContent from "../../../utils/descriptiveContent";
import CopyText from "../../dynamic-page/util/copyText";

const Item = ({ item, product, pageData, handleTableFilters }) => {
  const [visibleModal, setVisibleModal] = React.useState(false);

  const salesListing = item?.sales_channel_listing
    ? item?.sales_channel_listing
    : item?.sales_channel_listings?.[0];

  const productList = product[0];
  const dispatch = useDispatch();

  return (
    <div
      className={
        " gap-[0px] h-[104px] flex flex-col justify-between !w-full !px-1 !py-[12px]"
      }
    >
      <div className=" whitespace-pre-wrap flex flex-1 justify-start flex-row ">
        <util.truncateText
          maxLines={3}
          popUpToggleClassName="w-full"
          className={"text-[14px] leading-[18px] text-[#111928]"}
          tooltip={true}
          innerClassName="text-left "
          ellipsisClass="!top-0 !right-0 h-fit"
        >
          {salesListing?.name}
        </util.truncateText>
        {/* ) : (
                  "-"   
                )} */}
      </div>

      <div className={"flex justify-between"}>
        <div className={"flex flex-row gap-[4px] text-gray-500"}>
          {util.shortenString(salesListing?.brand, 30)}
          <util.copyText
            className={"text-[14px]"}
            text={salesListing?.brand}
            hint={false}
          />
          {descriptiveContent(
            <HiFilter
              onClick={() => {
                handleTableFilters(
                  {
                    brand: [productList?.brand?.name],
                    assign_id: pageData?.filters?.filters?.assign_id
                      ? [pageData?.filters?.filters?.assign_id]
                      : null,
                    product_id: pageData?.filters?.filters?.product_id
                      ? [pageData?.filters?.filters?.product_id]
                      : null,
                  },
                  {
                    brand: {
                      value: productList?.brand?.id,
                      label: productList?.brand?.name,
                    },
                    product_id: {
                      value: pageData?.boundFilters?.product_id?.value || null,
                      label: pageData?.boundFilters?.product_id?.label || null,
                    },
                    assign_id: {
                      value: pageData?.boundFilters?.assign_id?.value || null,
                      label: pageData?.boundFilters?.assign_id?.label || null,
                    },
                  }
                );
              }}
              className="text-gray-300 hover:text-gray-500 w-[12px] h-[12px] cursor-pointer"
            />,
            "Filter by Brand"
          )}
        </div>
        {productList ? (
          <div className="flex items-center gap-[6px] !text-[12px]">
            <span
              className={`w-[18px] h-[18px] rounded-full  flex justify-center items-center text-xs font-medium ${
                productList?.type === 1
                  ? "text-teal-700 bg-teal-100"
                  : "text-indigo-700 bg-indigo-100"
              } p-[0px]`}
            >
              {productList?.type === 1 ? "S" : "G"}
            </span>
            <div
              className={
                "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row items-center gap-[6px] "
              }
            >
              {productList?.brand?.assigned ? (
                <>
                  <button
                    onClick={() => {
                      window.open(`/product/${productList?.id}`, "_blank");
                    }}
                    className={
                      "text-blue-700 hover:text-blue-800 cursor-pointer text-[14px] leading-[21px] flex flex-row items-center gap-[6px]"
                    }
                  >
                    <img
                      alt="avatar"
                      src={
                        productList?.brand?.assigned?.image_url ||
                        "/assets/images/defaultAvatar.png"
                      }
                      className={"w-[18px] h-[18px] rounded-full object-cover"}
                    />
                    {util.upperCaseString(
                      productList?.brand?.assigned?.name,
                      "word"
                    )}
                  </button>
                  <CopyText
                    className={"text-[12px]"}
                    text={productList?.apid}
                    hint={false}
                  />
                  {descriptiveContent(
                    <HiFilter
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTableFilters(
                          {
                            ...pageData?.filters?.filters,
                            assigned_user: [
                              salesListing?.product?.brand?.assigned.id,
                            ],
                          },
                          {
                            ...pageData?.boundFilters,
                            assigned_user: {
                              value: salesListing?.product?.brand?.assigned?.id,
                              label:
                                salesListing?.product?.brand?.assigned?.name,
                            },
                          }
                        );
                      }}
                      className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
                    />,
                    "Filter by Assign User"
                  )}
                </>
              ) : (
                <button
                  className="text-gray-400"
                  onClick={() => {
                    window.open(`/product/${productList?.id}`, "_blank");
                  }}
                >
                  Unassigned
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-[2px] !text-[12px]">
            <button
              className={"flex flex-row gap-[2px]"}
              onClick={() => {
                setVisibleModal(true);
              }}
            >
              <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                <Icons.Link className={"w-[11px] h-[11px]"} />
              </span>
              <div
                className={"text-red-700 hover:text-red-800 cursor-pointer "}
              >
                Not Connected
              </div>
            </button>
          </div>
        )}

        {/* } */}
      </div>

      {visibleModal && (
        <ConnectForm
          data={item}
          show={visibleModal}
          onClose={() => {
            setVisibleModal(false);
          }}
          defaultListings={[item]}
          //   callBack={() => {
          //     onPaginationApply();
          //     updateListingsCount("account-listing", "sales");
          //   }}
          updateRows={(items) => {
            items.map((item) => {
              dispatch(fetchSalesListing(item)).then((rs) => {
                dispatch(
                  updateRow({
                    path: `fcRestockings.[id:${item}]`,
                    value: rs.payload.data,
                  })
                );

                dispatch(
                  updateRow({
                    path: `salesListings.[id:${item}]._updatedRow`,
                    value: true,
                  })
                );
              });
            });
          }}
        />
      )}
    </div>
  );
};
Item.propTypes = {
  item: PropTypes.object.isRequired,
  product: PropTypes.array.isRequired, // Adjust the type based on your data structure
  handleTableFilters: PropTypes.func.isRequired, // Validation for handleTableFilters
  pageData: PropTypes.shape({
    // Validation for pageData
    filters: PropTypes.shape({
      filters: PropTypes.object,
    }),
    boundFilters: PropTypes.shape({
      product_id: PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      }),
      assign_id: PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Item;

import React from "react";
import { IoStorefront } from "react-icons/io5";
import descriptiveContent from "../../../../utils/descriptiveContent";
import PopupUp from "../../../dynamic-page/util/popUp";
import CopyText from "../../../dynamic-page/util/copyText";
import upperCase from "../../../dynamic-page/util/upperCaseString";
import PropTypes from "prop-types";
import Icons from "../../icons";

const Track = Icons.track;

const ShipmentIcon = ({ flag }) => {
  return flag ? (
    <img
      alt="flag"
      className={"w-[22.4px] mt-1 rounded-[3px] h-[16px] cursor-pointer object-cover"}
      src={flag}
    />
  ) : (
    <IoStorefront className={"w-4 h-4 text-green-300"} />
  );
};

ShipmentIcon.propTypes = {
  flag: PropTypes.string,
};

const shipmentToggle = flag => () => (
  <ShipmentIcon flag={flag} />
)

const ShipmentLabelView = ({ shipmentData }) => {
  const primaryShipmentLabel = shipmentData?.find((item) => {
    return item?.is_primary === 1 && item.is_hidden === 0;
  });

  if (!shipmentData || !primaryShipmentLabel) {
    return null;
  }

  return (
    <div className={"max-w-[450px] min-w-[264px] w-full"}>
      <div
        className={"flex flex-col gap-[4px] justify-start w-full"}
        style={{ width: "100%" }}
      >
        <div
          className={
            "flex flex-row justify-between items-center gap-[16px] w-full"
          }
          style={{ width: "100%" }}
        >
          <div className="w-full">
            <div
              className={
                "flex flex-row gap-[4px] w-full items-center justify-between"
              }
            >
              <div className="flex flex-row gap-[4px] w-full items-center">
                <PopupUp
                  toggle={shipmentToggle(primaryShipmentLabel?.from_state?.country?.flag)}
                  action={"hover"}
                >
                  <div className={"text-[12px] text-gray-900 font-medium"}>
                    {primaryShipmentLabel?.from_state?.name}
                  </div>
                </PopupUp>
                {descriptiveContent(
                  primaryShipmentLabel?.international_carrier?.logo ? (
                    <img
                      alt="logo"
                      className={"w-[16px] h-[16px] object-contain"}
                      src={primaryShipmentLabel?.international_carrier?.logo}
                    />
                  ) : (
                    <Track className={"w-[16px] h-4 text-gray-300"} />
                  ),
                  primaryShipmentLabel?.international_carrier?.name
                )}
                <span className="text-[#1A56DB]">
                  {primaryShipmentLabel?.tracking_number}
                </span>
                <CopyText
                  className={"text-[12px]"}
                  text={primaryShipmentLabel?.tracking_number}
                  hint={""}
                />
              </div>
              <span className="text-[14px] leading-[21px] font-semibold text-[#BF125D]">
                {primaryShipmentLabel?.billed_cost && (
                  <span>${primaryShipmentLabel?.billed_cost}</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          className={"flex flex-row w-full justify-between gap-[16px]"}
          style={{ width: "100%" }}
        >
          <span className={"text-gray-600 flex-grow"}>
            {primaryShipmentLabel?.service_name
              ? upperCase(primaryShipmentLabel?.service_name, "word")
              : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

ShipmentLabelView.propTypes = {
  shipmentData: PropTypes.array.isRequired,
};

export default ShipmentLabelView;

import React from "react";
import Icons from "../../icons";

import {
  fetchSingleOrder,
  updateBillableWeight,
  updateRow,
} from "../../../../store/orders";
import { useDispatch, useSelector } from "react-redux";
import notifier from "../../../dynamic-page/util/notifier";
import SaveWeight from "../table/saveWeight";
import PropTypes from "prop-types";
import PopupUp from "../../../dynamic-page/util/popUp";

const Weight = ({ weight, order_status_id, view }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.orders.orders);
  const weightInRedux =
    data?.find((item) => item?.id === weight?.orderId)?.billable_weight || "-";
  const handleSaveWeight = async (t) => {
    if (view) {
      return;
    }
    await dispatch(
      updateBillableWeight({
        orderId: weight?.orderId,
        billable_weight: Number(t),
      })
    ).then((rs) => {
      if (rs?.payload?.data?.type === "success") {
        const w = t;
        notifier({
          message: w
            ? `Billable Weight updated to  ${w}`
            : "Billable Weight has been removed",
          type: "success",
        });
        dispatch(fetchSingleOrder(weight?.orderId)).then((rs) => {
          dispatch(
            updateRow({
              path: `orders.[id:${weight?.orderId}].billable_weight`,
              value: w,
            })
          );
          dispatch(
            updateRow({
              path: `orders.[id:${weight?.orderId}]._updatedRow`,
              value: true,
            })
          );
          // remove the updated row after 5 seconds
          setTimeout(() => {
            dispatch(
              updateRow({
                path: `orders.[id:${weight?.orderId}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
        });
      }
    });

    return true;
  };
  const handleIsEdited = () => {
    if (weight?.selected === "actual") {
      if (weight?.billable !== weight?.actual) {
        return true;
      }
    } else if (weight?.billable !== weight?.dim) {
      return true;
    }
    return false;
  };

  const Tarazo = Icons.weightTarazo;
  const Box = Icons.weightBox;
  let selectedIcon;

  if (weight?.selected === "actual") {
    selectedIcon = (
      <Tarazo className={"w-[14px] h-[14px] flex text-purple-800"} />
    );
  } else if (weight?.selected === "dimensional") {
    selectedIcon = <Box className={"w-[14px] h-[14px] flex text-purple-800"} />;
  } else {
    selectedIcon = "";
  }
  return (
    <div className={"flex flex-col  justify-center"}>
      {/*{weight?.billable  &&*/}

      <div className={"flex flex-row gap[0px] items-center justify-center"}>
        <SaveWeight
          nullable={true}
          bigger={weight?.selected}
          selected={selectedIcon}
          shipments={true}
          isWeightEdited={handleIsEdited()}
          text={weightInRedux}
          onSave={handleSaveWeight}
          className={`min-w-max  text-[18px] !font-semibold  text-purple-800  "
            } `}
        />
      </div>

      {order_status_id !== 14 && (
        <div className={"flex flex-row gap-[4px] text-[12px] justify-center"}>
          {weight?.selected !== "actual" ? (
            <PopupUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "actual" && "!opacity-100"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center  text-medium`}
                >
                  <Tarazo className={"h-[1em]"} />
                  {weight?.actual}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Actual Weight
            </PopupUp>
          ) : (
            <PopupUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "dimensional" && "opacity-100"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center text-medium"`}
                >
                  <Box className={"h-[1em]"} />
                  {weight?.dim}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Dimensional Weight
            </PopupUp>
          )}
        </div>
      )}
    </div>
  );
};

Weight.propTypes = {
  weight: PropTypes.shape({
    orderId: PropTypes.number,
    selected: PropTypes.string,
    billable: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    actual: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dim: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  view: PropTypes.bool.isRequired,
  order_status_id: PropTypes.number.isRequired,
};

export default Weight;

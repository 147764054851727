import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { HiColorSwatch, HiStar, HiFilter } from "react-icons/hi";
import PropTypes from "prop-types"; // Import PropTypes
import descriptiveContent from "../../../utils/descriptiveContent";
import { useSelector } from "react-redux";
import CopyText from "../../dynamic-page/util/copyText";

const Item = ({
  item,
  product,
  connect,
  hideVariant,
  hide,
  handleTableFilters,
}) => {
  const salesListing = item?.sales_channel_listing
    ? item?.sales_channel_listing
    : item?.sales_channel_listings?.[0];

  const productList = product[0];
  const pageData = useSelector((state) => state.salesListing);

  return (
    <div className={"w-full gap-[0px] flex flex-col !px-[8px] !py-[12px]"}>
      {!hideVariant && (
        <div className="flex justify-between text-[14px] font-bold text-orange-700">
          <div className="flex items-center justify-between gap-2">
            <p>
              {salesListing?.["reviews_count"]
                ? salesListing?.["reviews_count"] + " Ratings"
                : "No Rating"}
            </p>
            <div className="flex items-center text-orange-600">
              {salesListing?.["ratings"] && <HiStar />}
              &nbsp;
              {salesListing?.["ratings"]
                ? (+salesListing?.["ratings"])?.toFixed(1)
                : "-"}
            </div>
          </div>
          <div className="flex items-center text-orange-600">
            <HiColorSwatch />
            &nbsp;
            {salesListing?.["variation_count"]
              ? salesListing?.["variation_count"] + " Variants"
              : "No variant"}
          </div>
        </div>
      )}
      <div className="w-full flex flex-row h-[63px] whitespace-pre-wrap">
        {salesListing?.name || salesListing?.product?.title ? (
          <util.truncateText
            maxLines={3}
            popUpToggleClassName='w-full'
            className={"text-[14px] text-[#111928]"}
            tooltip={true}
            innerClassName='text-left !leading-[18px]'
            ellipsisClass='!top-0 !right-0 h-fit'
          >
            {productList?.brand?.assigned
              ? salesListing?.product?.title
              : salesListing?.name}
          </util.truncateText>
        ) : (
          "-"
        )}
      </div>

      <div className={"flex justify-between"}>
        <div className={"flex flex-row gap-[4px] text-gray-500"}>
          {util.shortenString(salesListing?.brand, 30)}
          {!hide?.brandFilter && (
            <util.copyText
              className={"text-[14px]"}
              text={salesListing?.brand}
              hint={false}
            />
          )}
          {hide?.brandFilter &&
            descriptiveContent(
              <HiFilter
                onClick={() => {
                  handleTableFilters(
                    {
                      ...pageData?.filters?.filters,
                      brand: [salesListing?.brand],
                    },
                    {
                      ...pageData?.boundFilters,
                      brand: {
                        value: salesListing?.brand,
                        label: salesListing?.brand,
                      },
                    }
                  );
                }}
                className="text-gray-300 hover:text-gray-500 w-[12px] h-[12px] cursor-pointer"
              />,
              "Filter by Brand"
            )}
        </div>
        {productList ? (
          <div className="flex items-center gap-[6px] !text-[12px]">
            <span
              className={`w-[18px] h-[18px] rounded-full  flex justify-center items-center text-xs font-medium ${
                productList?.type === 1
                  ? "text-teal-700 bg-teal-100"
                  : "text-indigo-700 bg-indigo-100"
              } p-[0px]`}
            >
              {productList?.type === 1 ? "S" : "G"}
            </span>
            <div
              className={
                "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row items-center gap-[6px] "
              }
            >
              {productList?.brand?.assigned ? (
                <>
                  
                  <button
                    onClick={() => {
                      window.open(`/product/${productList?.id}`, "_blank");
                    }}
                    className={
                      "text-blue-700 hover:text-blue-800 cursor-pointer text-[14px] leading-[21px] flex flex-row items-center gap-[6px]"
                    }
                  >
                    <img
                    alt="avatar"
                    src={
                      productList?.brand?.assigned?.image_url ||
                      "/assets/images/defaultAvatar.png"
                    }
                    className={"w-[18px] h-[18px] rounded-full object-cover"}
                  />
                    {util.upperCaseString(
                      productList?.brand?.assigned?.name,
                      "word"
                    )}
                  </button>
                  <CopyText
                    className={"text-[12px]"}
                    text={productList?.apid}
                    hint={false}
                    iconClass={"!w-[14px] !h-[14px]"}
                  />
                  {hide?.brandFilter &&
                    descriptiveContent(
                      <HiFilter
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTableFilters(
                            {
                              ...pageData?.filters?.filters,
                              assigned_user: [
                                salesListing?.product?.brand?.assigned.id,
                              ],
                            },
                            {
                              ...pageData?.boundFilters,
                              assigned_user: {
                                value:
                                  salesListing?.product?.brand?.assigned?.id,
                                label:
                                  salesListing?.product?.brand?.assigned?.name,
                              },
                            }
                          );
                        }}
                        className="text-gray-300 hover:text-gray-500 w-[14px] h-[14px] cursor-pointer"
                      />,
                      "Filter by Assign User"
                    )}
                </>
              ) : (
                <button className="text-gray-400" onClick={() => {
                  window.open(`/product/${productList?.id}`, "_blank");
                }}>Unassigned</button>
              )}
            </div>
          </div>
        ) : (
          <button
            className={"flex flex-row gap-[2px]"}
            onClick={() => {
              connect.onClick(item);
            }}
          >
            <span className="w-[18px] h-[18px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
              <Icons.Link className={"w-[11px] h-[11px]"} />
            </span>
            <div className={"text-red-700 hover:text-red-800 cursor-pointer "}>
              Not Connected
            </div>
          </button>
        )}

        {/* } */}
      </div>
    </div>
  );
};
Item.propTypes = {
  item: PropTypes.shape({
    sales_channel_listing: PropTypes.object,
    sales_channel_listings: PropTypes.array,
  }).isRequired,
  product: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.shape({
        assigned: PropTypes.object,
      }),
      apid: PropTypes.string,
      type: PropTypes.number,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  connect: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  hideVariant: PropTypes.bool,
  hide: PropTypes.shape({
    brandFilter: PropTypes.bool,
  }),
  handleTableFilters: PropTypes.func.isRequired,
};

export default Item;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Icons from "../../icons";
import util from "../../../dynamic-page/util";
import descriptiveContent from "../../../../utils/descriptiveContent";

function EditableText({
  text,
  onSave,
  className,
  outerClassName,
  nullable,
  buttonClassName,
  isWeightEdited,
  shipments,
  selected,
  isQuantity,
  bigger,
}) {
  const [innerText, setInnerText] = useState(text);
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const contentRef = useRef(null);

  const handleInput = (event) => {
    const inputText = event.target.innerText;

    if (isQuantity) {
      // Only allow integers if isQuantity is true
      if (/^\d+$/.test(inputText)) {
        setInnerText(inputText);
        setIsEdited(true);
      }
    } else if (/^(?![.-])\d*\.?\d{0,2}$/.test(inputText)) {
      setInnerText(inputText);
      setIsEdited(true);
    }

    // Allow valid decimal numbers but prevent starting with a dot or dash

    setInnerText(inputText);
    setIsEdited(true);
  };

  const handleCancel = (event) => {
    const { relatedTarget, key } = event;

    if (text !== innerText && contentRef.current) {
      if (
        (relatedTarget && relatedTarget.id === "weightSaveButton") ||
        key === "Enter"
      ) {
        handleSave();
      } else {
        contentRef.current.textContent = text;
      }
    }

    setIsEditing(false);
  };

  const handleKeyUp = (event) => {
    const key = event.key;
    if (key === "Escape") {
      handleCancel(event);
      handleEdit();
      event.preventDefault();
    }
  };

  const handleKeyDown = async (event) => {
    const key = event.key;
    const currentValue = contentRef.current.innerText;

    if (isQuantity) {
      // Prevent decimal and dash input if isQuantity is true
      if (!/^\d$/.test(key)) {
        event.preventDefault();
      }
    } else if (
      (key === "." && currentValue === "") ||
      (key === "." && currentValue.includes(".")) ||
      key === "-" ||
      !/[\d.]/.test(key)
    ) {
      // Prevent dot or dash as the first character, or multiple dots

      event.preventDefault();
    }

    if (key === "Enter") {
      handleSave();
      event.preventDefault();
    }

    if (key === "Escape") {
      handleCancel(event);
      handleEdit();
      event.preventDefault();
    }
  };
  const handleSave = async () => {
    let number = contentRef?.current?.textContent;

    // Prevent submission if the value starts with a dash, ends with a dot, or is just a dot
    if (
      number &&
      (number.startsWith("-") || number.endsWith(".") || number === ".")
    ) {
      util.notifier({ type: "error", message: "Invalid number format." });
      return;
    }

    if (nullable) {
      setIsSaving(true);
      await onSave(number);
      setIsSaving(false);
      handleEdit();
      return;
    }
    if (number.includes(",")) {
      number = number.replace(",", "");
    }
    if (number && +number > 0) {
      setIsSaving(true);
      await onSave(number);
      setIsSaving(false);
      handleEdit();
    } else {
      util.notifier({ type: "error", message: "Value must be greater than 0" });
      contentRef.current.textContent = text;
    }
  };

  function handleEdit() {
    if (isEdited) {
      setIsEdited(false);
    }
    if (!isEditing) {
      setIsEditing(true);
    }
    contentRef.current.blur();
  }

  useEffect(() => {
    if (isEditing) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(contentRef.current);
      if (innerText !== "-") {
        range.collapse(false);
      }

      selection.removeAllRanges();
      selection.addRange(range);

      contentRef?.current?.focus();
    }
    setInnerText(text);
  }, [isEditing]);

  useEffect(() => {
    // Reset if text changes externally
    setInnerText(text);
  }, [text]);

  function getButtonContent() {
    if (isSaving) {
      return <Icons.Clock className={"text-[rgb(108,43,217,0.15)]"} />;
    }

    if (isEditing) {
      return (
        <button id={"weightSaveButton"} className={`w-[15px] h-[15px]`}>
          <Icons.Check className={"text-blue-500"} />
        </button>
      );
    }

    return (
      <Icons.EditOutline className={"text-gray-300 hover:text-gray-500"} />
    );
  }

  let popupPosition;
  if (isWeightEdited) {
    popupPosition = "Billable Weight";
  } else if (bigger === "actual") {
    popupPosition = "Actual Weight";
  } else {
    popupPosition = "Dimensional Weight";
  }

  return (
    <div
      className={`flex flex-row items-center justify-center gap-[4px] w-full min-w-0 ${
        outerClassName || ""
      }`}
    >
      {!text && !isEditing && (
        <span
          className={`focus:outline-none focus:px-[5px] rounded-[6px] focus:bg-[rgb(108,43,217,0.15)] h-[25px] leading-[25px]   ${className} ${
            isSaving ? "opacity-30" : ""
          }`}
        >
          -
        </span>
      )}
      {isWeightEdited && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M13.1611 0.823323C12.1543 -0.274441 10.5154 -0.274441 9.50853 0.823323L2.64424 8.31165C2.57558 8.38656 2.53002 8.47477 2.49858 8.56789C2.49665 8.57419 2.49087 8.57839 2.48895 8.58469L1.11635 13.078C1.03934 13.3293 1.09966 13.6072 1.27164 13.7942C1.39356 13.9279 1.55784 14 1.72532 14C1.79334 14 1.86201 13.9881 1.9281 13.9636L6.04591 12.4654C6.05168 12.4633 6.05553 12.4577 6.06067 12.4556C6.14665 12.422 6.22751 12.3716 6.29617 12.2966L13.1611 4.80902C14.1686 3.71056 14.1686 1.92179 13.1611 0.823323ZM8.58897 3.80577L9.05549 4.31475C9.05421 4.31615 9.05228 4.31685 9.051 4.31825L4.47117 9.3142L4.00593 8.80662L8.58897 3.80577ZM2.73986 12.193L3.37835 10.1025L4.65534 11.4957L2.73986 12.193ZM5.84313 10.8117L5.37789 10.3041L9.95773 5.3082C9.95901 5.3068 9.95965 5.3047 9.96093 5.3033L10.4268 5.81157L5.84313 10.8117ZM12.2537 3.81907L11.3348 4.82162L9.49634 2.81582L10.4153 1.81327C10.9222 1.26019 11.7468 1.26089 12.2531 1.81327C12.7607 2.36635 12.7607 3.26599 12.2537 3.81907Z"
            fill="#5521B5"
          />
        </svg>
      )}
      {!isWeightEdited && selected}
      {shipments ? (
        descriptiveContent(
          <button
            type="button"
            contentEditable={isEditing}
            tabIndex={0}
            ref={contentRef}
            onInput={handleInput}
            onBlur={handleCancel}
            onKeyPress={handleKeyDown}
            onKeyUp={handleKeyUp}
            onFocus={() => setIsEditing(true)}
            className={`min-w-0 flex-shrink focus:outline-none focus:px-[5px] rounded-[6px] focus:bg-[rgba(108,43,217,0.15)] h-[25px] leading-[25px] ${className} ${
              isSaving ? "opacity-30" : ""
            } max-w-[150px] overflow-hidden  whitespace-nowrap`}
          >
            {text}
          </button>,
          popupPosition,
          "!text-[12px] !font-normal !text-gray-900",
          "top"
        )
      ) : (
        <button
          type="button"
          contentEditable={isEditing}
          tabIndex={0}
          ref={contentRef}
          onInput={handleInput}
          onBlur={handleCancel}
          onKeyPress={handleKeyDown}
          onKeyUp={handleKeyUp}
          onFocus={() => setIsEditing(true)}
          className={`focus:outline-none focus:px-[5px] rounded-[6px] focus:bg-[rgb(108,43,217,0.15)] h-[25px] leading-[25px]   ${className} ${
            isSaving ? "opacity-30" : ""
          } max-w-[150px]`} // Limiting max width
        >
          {text}
        </button>
      )}

      <button
        className={`transition items-start flex-col flex w-[12px] h-[12px] cursor-pointer  ${
          buttonClassName || ``
        }`}
        onClick={handleEdit}
      >
        {getButtonContent()}
      </button>
    </div>
  );
}
EditableText.propTypes = {
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  nullable: PropTypes.bool,
  buttonClassName: PropTypes.string,
  isWeightEdited: PropTypes.bool,
  shipments: PropTypes.bool,
  selected: PropTypes.node,
  isQuantity: PropTypes.bool,
  bigger: PropTypes.string,
};

export default EditableText;

import React from 'react'
import PropTypes from 'prop-types'
import icons from '../../orders/icons'
import priceHandler from '../../../utils/priceHandler'

function ListingSales({item, noArrows}) {
    return (
        <div className=" justify-start  items-center inline-flex h-[104px]">
            {item?.weekly && <div className=" gap-3  flex-col justify-between items-start py-4 px-3  inline-flex">
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium !leading-3">
                        7d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.weekly?.sales ? item?.weekly?.sales : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium !leading-3">
                        7d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.weekly?.revenue ? '$' + priceHandler(item?.weekly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
            {item?.monthly && <div className="  gap-3 flex-col justify-between items-start py-4 px-3  inline-flex">
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium !leading-3">30d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.monthly?.sales ? item?.monthly?.sales : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium !leading-3">30d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.monthly?.revenue ? '$' + priceHandler(item?.monthly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
            {item?.biMonthly && <div className="   flex-col justify-between items-start  inline-flex">
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium leading-3">60d Sales</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.biMonthly?.sales ? priceHandler(item?.biMonthly?.sales) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
                <div className="flex-col justify-start items-start flex gap-1">
                    <div className="text-[#374151] text-xs font-medium leading-3">60d Revenue</div>
                    <div className="justify-start items-center gap-0.5 inline-flex">
                        <div className="text-green-800 text-[16px] font-semibold leading-4">
                            {item?.biMonthly?.revenue ? '$' + priceHandler(item?.biMonthly?.revenue) : 0}
                        </div>
                        {!noArrows && <icons.tradingUp className='text-gray-300'/>}
                    </div>
                </div>
            </div>}
        </div>
    )
}
ListingSales.propTypes = {
    item: PropTypes.shape({
        weekly: PropTypes.shape({
            sales: PropTypes.number,
            revenue: PropTypes.number,
        }),
        monthly: PropTypes.shape({
            sales: PropTypes.number,
            revenue: PropTypes.number,
        }),
        biMonthly: PropTypes.shape({
            sales: PropTypes.number,
            revenue: PropTypes.number,
        }),
    }).isRequired,
    noArrows: PropTypes.bool
}

export default ListingSales
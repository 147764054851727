import upperCaseString from "../../dynamic-page/util/upperCaseString";

const FiltersView = (selectedFilers, bound, filtersMeta) => {
  return [
    {
      name: "status",
      label: "Offer Status",
      placeholder: "Filter by offer status",
      type: "Checkbox",
      list: filtersMeta?.statuses?.map((item) => ({
        label: upperCaseString(item?.name),
        value: item?.name,
        count: item?.count,
      })),
      defaultValue: selectedFilers?.status,
      toggle: true,
      clear: true,
      features: {
        isMulti: true,
      },
      checkAll: true,
    },
    {
      name: "identifier",
      label: "Identifier",
      placeholder: "Filter by identifier",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.identifier,
    },
    {
      name: "sku",
      label: "SKU",
      placeholder: "Filter by sku",
      type: "Input",
      toggle: true,
      isClearable: true,
      defaultValue: selectedFilers?.sku,
    },
    {
      name: "product_ids",
      label: "Product",
      type: "Select",
      api: {
        url: "/api/v1/product",
        query: "?term={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: upperCaseString(item?.title, "word", true),
        }),
      },
      reset: true,
      clear: true,
      toggle: true,
      style: {
        control: {
          minHeight: "auto",
        },
        menu: {
          whiteSpace: "wrap !important",
        },
      },
      placeholder: "Filter by products",
      defaultValue: selectedFilers?.product_ids,
    },
    {
          name: "assigned",
          label: "Assigned Employee",
          placeholder: "Filter by employee",
          type: "Select",
          api: {
            url: "/api/v1/users?filter[status]=1",
            view: "/api/v1/employees",
            skipCancel: true,
            query: "&filter[name]={query}",
            optionValue: (item) => ({
              value: item?.employee_id || item?.id,
              label: item?.name,
              avatar: item?.image_url || item?.profile_image,
            }),
          },
          features: {
            isMulti: true,
            formatOptionLabel: ({ label, avatar }) => (
              <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
                <span
                  className={
                    " overflow-hidden flex flex-col justify-center items-center "
                  }
                >
                  <img
                    alt="def"
                    src={avatar || "/assets/images/defaultAvatar.png"}
                    className="w-5 h-5 rounded-full object-contain"
                  />
                </span>
                <span>{upperCaseString(label)}</span>
              </div>
            ),
          },
          defaultValue: selectedFilers?.assigned,
          toggle: true,
          clear: true,
        },
    {
      name: "account",
      label: "Account",
      placeholder: "Filter by account",
      type: "Select",
      api: {
        url: "/api/v1/accounts?filter[status]=active&include=channel",
        view: "/api/v1/accounts",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.channel?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px] overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                alt="logo"
                src={logo || "/assets/images/img-placehoder.png"}
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.account,
      // defaultBound: boundFilters?.account || null,
      toggle: true,
      clear: true,
    },
    {
      name: "channel",
      label: "Channel",
      placeholder: "Filter by Channel",
      type: "Select",
      api: {
        url: "/api/v1/channel?filter[designation]=TARGET",
        view: "/api/v1/channel",
        query: "&filter[name]={query}",
        optionValue: (item) => ({
          value: item?.id,
          label: item?.name,
          logo: item?.logo,
        }),
      },
      features: {
        isMulti: true,
        formatOptionLabel: ({ label, logo }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] h-[15px]  overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img
                alt="logo"
                src={logo || "/assets/images/img-placehoder.png"}
              />
            </span>
            <span>{upperCaseString(label)}</span>
          </div>
        ),
      },
      defaultValue: selectedFilers?.channel,
      toggle: true,
      clear: true,
    },
    {
      name: "price",
      label: "Price",
      placeholder: "Filter by price",
      type: "Range",
      defaultValue: selectedFilers?.price,
      toggle: true,
      clear: true,
    },
  ];
};

export default FiltersView;

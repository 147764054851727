import React, { forwardRef, memo, useEffect, useState } from "react";
import Box from "../../../../../dynamic-page/elements/Box";
import SummaryView from "./summaryView";
import PropTypes from "prop-types";

const SummaryLeftSide = memo(
  forwardRef((props, ref) => {
    const {
      id,
      isView,
      callBack,
      orderItems,
      summaryForm,
      actionCallBack,
      selectedOrder,
      type,
    } = props;

    const [isEdit, setIsEdit] = useState(true);
    const [summaryData, setSummaryData] = useState(summaryForm);

    useEffect(() => {
      setSummaryData(summaryForm);
    }, [summaryForm]);

    useEffect(() => {
      if (isView) setIsEdit(false);
    }, [isView]);

    const handleSave = async (value, type) => {
      if (!isView) {
        callBack({
          [type]: value,
        });
      } else {
        actionCallBack(
          {
            [type]: value,
          },
          { action: "update" }
        );
      }
    };

    return (
      <Box
        className="!min-h-fit !h-fit"
      >
        <style>
          {`
                    .css-1u9des2-indicatorSeparator {display: none; }
                    .css-1xc3v61-indicatorContainer {padding-left: 0px; padding-right:6px }
                    .css-1xc3v61-indicatorContainer {padding-right: 12px;}
                    .css-1xc3v61-indicatorContainer svg {color:transparent !important; background: url('/assets/images/arrow.svg'); width:12px; height:12px; opacity:0.7}
                    input[type="date"]::before { opacity:1; right:12px; background: url('/assets/images/arrow.svg') no-repeat center !important; width: 12px !important; height:12px !important; opacity:0.7 !important; border:none !important; right:12px !important }
                    input[type="date"]::-webkit-datetime-edit-month-field { opacity:0.4 !important; }
                    input[type="date"].hasValue::-webkit-datetime-edit-month-field { opacity:1 !important; }
                    
                    input[type="date"]::-webkit-datetime-edit-text { opacity:0.4 !important; }
                    input[type="date"]::-webkit-datetime-edit-text { opacity:1 !important; }
                    
                `}
        </style>
        <div
          className={`flex flex-col  ${
            summaryForm?.status_id === "1" ? "min-h-[342px]" : "min-h-[586px]"
          }`}
        >
          <SummaryView
            id={id}
            orderItems={orderItems}
            selectedOrder={selectedOrder}
            actionCallBack={actionCallBack}
            summaryForm={summaryData}
            callBack={callBack}
            isView={isView}
            type={type}
            handleSave={handleSave}
          />
        </div>
      </Box>
    );
  })
);

SummaryLeftSide.propTypes = {
  id: PropTypes.string,
  isView: PropTypes.bool,
  callBack: PropTypes.func,
  orderItems: PropTypes.array,
  summaryForm: PropTypes.object,
  actionCallBack: PropTypes.func,
  selectedOrder: PropTypes.object,
  type: PropTypes.string,
};

export default SummaryLeftSide;

import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import isBase64 from "../../dynamic-form/util/isBase64";
import constant from "../../../constant";
import upperCase from "../../dynamic-page/util/upperCaseString";
import { toast } from "react-toastify";
import Icons from "../../orders/icons";
import Toggle from "../../dynamic-form/components/toggle";
import { HiTrash } from "react-icons/hi";
import descriptiveContent from "../../../utils/descriptiveContent";
import PropTypes from "prop-types";

const formView = (formDefaultValue) => {
  return [
    {
      name: "provider",
      label: "Provider",
      placeholder: "Select the provider",
      api: {
        url: "api/v1/logistics",
        query: "?filter[name]={query}",
        ignoreView: true,
        optionValue: (item) => ({
          value: item.id,
          label: upperCase(item.name, "first"),
          type: item.type,
        }),
      },
      type: "Select",
      condition: () => !formDefaultValue?.id,

      defaultValue: formDefaultValue?.provider || null,
      className: "col-span-2 ",
      innerClassName: "bg-gray-50",
      validation:
        !formDefaultValue?.id && Yup.string().required("Provider is required"),
    },
    {
      name: "carrier",
      label: "Carrier ",
      placeholder: "Select the carrier",
      type: "Select",
      disablePreload: true,
      notify: (message, type) => {
        toast[type](message);
      },
      api: {
        url: "/api/v1/logistics/{provider}/carriers",
        query: "?term=",
        ignoreView: true,
        // view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.cache_identifier,
          label: item.name,
        }),
      },
      condition: () => !formDefaultValue?.id,

      defaultValue: formDefaultValue?.carrier || null,
      className: "col-span-2",
      innerClassName: "bg-gray-50",
      validation:
        !formDefaultValue?.id && Yup.string().required("Carrier is required"),
    },
    {
      name: "dim_divisor",
      label: "DIM Weight Divisor",
      placeholder: "Select the divisor",
      list: [
        { label: "6000", value: "6000" },
        { label: "5000", value: "5000" },
        { label: "3000", value: "3000" },
      ],
      type: "Select",
      condition: () => !formDefaultValue?.id,
      defaultValue: formDefaultValue?.dim_divisor || "5000",
      className: "col-span-2",
      innerClassName: "bg-gray-50",
      validation: Yup.string().required("Divisor is required"),
    },
    {
      name: "international_carrier_id",
      label: "Associated International Identifier",
      placeholder: "ex. UPS",
      api: {
        url: "/api/v1/international-carriers/index",
        query: "?term={query}",
        ignoreView: true,
        // view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      type: "Select",
      defaultBound: formDefaultValue?.international_carrier?.id
        ? {
            id: formDefaultValue?.international_carrier?.id,
            name: formDefaultValue?.international_carrier?.name,
          }
        : null,
      defaultValue: formDefaultValue?.international_carrier_id || null,
      validation: Yup.string().required("International Carrier is required"),
      className: "col-span-6",
      innerClassName: "bg-gray-50",
    },
    {
      name: "country_id",
      label: "Country",
      placeholder: "Select the country",
      type: "Select",
      reset: false,
      clear: true,
      api: {
        url: constant.APIURL.GET_COUNTRIES,
        query: "?filter[name]={query}",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
          flag: item.flag,
        }),
      },
      features: {
        formatOptionLabel: ({ label, flag }) => (
          <div className={"flex flex-row gap-[12px] items-center min-h-[25px]"}>
            <span
              className={
                "w-[25px] object-cover overflow-hidden flex flex-col justify-center items-center "
              }
            >
              <img alt="flag" src={flag?.toLowerCase()} />
            </span>
            <span>{upperCase(label)}</span>
          </div>
        ),
      },
      defaultValue: formDefaultValue?.country_id || null,
      // validation: Yup.string().required("Country is required"),
      className: "col-span-3  !pb-1",
      innerClassName: "bg-gray-50 ",
    },
    {
      name: "state_id",
      label: "State",
      placeholder: "Select the state",
      type: "Select",
      reset: false,
      clear: true,
      features: {
        formatOptionLabel: (child) => (
          <div className={"flex flex-row items-center gap-[8px]"}>
            <span className={"text-gray-800"}>{child?.data?.name}</span>
          </div>
        ),
      },
      api: {
        url: "/api/v1/state?filter[country_id]={country_id}",
        query: "&term={query}",
        view: "/api/v1/state",
        optionValue: (item) => ({
          value: item.id,
          label: item.name,
        }),
      },
      defaultValue: formDefaultValue?.state_id || null,
      execludedValues: (data) => data?.state_ids?.map((item) => item.id),
      className: "col-span-3",
      innerClassName: " bg-gray-50",
      // validation: Yup.string().required("State is required"),
    },
    
    {
      name: "state_ids",
      type: "Module",
      module: StateIDS,
      className: "col-span-6",
      defaultValue: formDefaultValue?.states || [],
      labelClassName: "!text-gray-900 !font-medium hidden",
      condition: (formData, boundData) =>
        formData?.state_id || formData?.state_ids?.length > 0,
    },
    {
      name: "cost_list",
      label: "Upload Cost List",
      labelDetails:
        "Upload cost list because of costs are not automatically updated via API. In such cases, manually uploading these lists is required to ensure accurate shipping cost calculations.",
      placeholder: "Select a Single Product to add more to the Grouped Product",
      type: "Module",
      // links: 'https://docs.google.com/spreadsheets/d/1I7RU_2Qb9JGGEwnATt-eSoiAps7ooSCS5btooGDfXhI/edit#gid=587040869',
      links: (
        <button
          className="text-[12px] leading-[18px] text-blue-600 underline cursor-pointer"
          onClick={() =>
            window.open(
              "https://docs.google.com/spreadsheets/d/1I7RU_2Qb9JGGEwnATt-eSoiAps7ooSCS5btooGDfXhI/edit#gid=587040869",
              "_blank"
            )
          }
        >
          Cost List Template
        </button>
      ),
      module: Upload,
      className: "col-span-6",
      defaultValue: formDefaultValue?.cost_list || null,
      labelClassName: "!text-gray-900 !font-medium !leading-[14px]",
      // optional: true,
      condition: (_, boundData) => boundData?.provider?.type == 0,
    },
    {
      name: "currency_id",
      label: "Cost List's Currency",
      placeholder: "USD",
      type: "Select",
      api: {
          url: constant.APIURL.GET_CURRENCIES + "?filter[status]=1",
          view: constant.APIURL.GET_CURRENCIES,
          query: "&term={query}",
          optionValue: (item) => ({
              value: item.id,
              label: upperCase(item.code),
          }),
      },
      defaultValue:
          formDefaultValue?.currency?.id ||
          formDefaultValue?.currency_id ||
        2,
      clear: false,
      reset: false,
      innerClassName: " bg-gray-50",
      className: "col-span-6",
      condition: (_, boundData) => boundData?.provider?.type == 0,
  },
    {
      name: "enable_fuel_surcharge",
      label: "Enable Fuel Surcharge",
      description: (
        <>
          Mark this checkbox for carriers with fuel surcharges. Refer to{" "}
          <button
            className="underline cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.dhl.de/en/geschaeftskunden/express/produkte-und-services/zuschlaege/treibstoffzuschlag-air.html"
              )
            }
          >
            DHL
          </button>
          ,{" "}
          <button
            className="underline cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.ups.com/tr/en/support/shipping-support/shipping-costs-rates/fuel-surcharges.page"
              )
            }
          >
            {" "}
            FedEx
          </button>
          , or{" "}
          <button
            className="underline cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.fedex.com/en-us/shipping/fuel-surcharge.html"
              )
            }
          >
            {" "}
            UPS
          </button>{" "}
          for the latest rates.
        </>
      ),
      placeholder: "blach",
      type: "Module",
      module: Toggle,
      checkbox: true,
      className: "col-span-6",
      defaultValue: !!formDefaultValue?.enable_fuel_surcharge,
      labelClassName: "!text-gray-900 !font-medium hidden ",
      // optional : true,
      innerClassName: "bg-gray-50",
      condition: (_, boundData) => boundData?.provider?.type == 0,
    },
    {
      name: "fuel_surcharge",
      label: "Fuel Surcharge",
      placeholder: "30.25",
      type: "Input",
      defaultValue: formDefaultValue?.fuel_surcharge || null,
      // validation: Yup.string().required("Name is required"),
      hint: "Update the multiplier here to reflect those changes. This ensures accurate shipping costs.",
      className: "col-span-6",
      innerClassName: "bg-gray-50",
      condition: (formData, boundData) =>
        formData?.enable_fuel_surcharge && boundData?.provider?.type == 0,
    },
  ];
};
export default formView;

const Upload = ({ change }) => {
  const [dragging, setDragging] = useState(false);
  const [uploading] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    uploadFile(e.target.files);
    e.target.value = null;
    e.target.removeEventListener("change", handleFileChange);
    e.target.addEventListener("change", handleFileChange);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files;
    const filesArray = Array.from(droppedFile);
    // check for the files types
    const allowedTypes = [
      "text/csv",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!filesArray.every((file) => allowedTypes.includes(file.type))) {
      toast.info("Invalid file type");
      return;
    }
    uploadFile(droppedFile);
  };

  const uploadFile = (file) => {
    if (file.length === 0) return;
    let files = Array.from(file);

    const notifications = []; // Array to store all notifications

    // Check for files larger than 10MB
    const bigFiles = files.filter((file) => file.size > 10000000);
    if (bigFiles.length > 0) {
      bigFiles.forEach((file) => {
        notifications.push(
          <li className={"opacity-50"}>{file.name} is too large.</li>
        );
      });
      files = files.filter((file) => file.size <= 10000000);
    }

    // Check for invalid file types
    const allowedTypes = [
      "text/csv",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const invalidFiles = files.filter(
      (file) => !allowedTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((file) => {
        notifications.push(
          <li className={"opacity-50"}>{file.name} is invalid.</li>
        );
      });
      files = files.filter((file) => allowedTypes.includes(file.type));
    }

    // Display combined notification
    if (notifications.length > 0) {
      toast.info(
        <>
          <b>File Upload Warnings:</b>
          <ul className={"list-disc list-inside my-[5px]"}>{notifications}</ul>
        </>
      );
    }

    setFile(files[0]);
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        if (isBase64(base64)) {
          change(base64, "cost_list");
        }
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const Upload = Icons.uploadSolid;

  return (
    <>
      <style>{`
                      .drag-drop-area {
                        border: 2px dashed #E5E7EB;
                        padding: 20px;
                        text-align: center;
                        cursor: pointer;
                        transition: all 0.9s ease;
                        position: relative; 
                    }
                    
                    .drag-drop-area.dragging {
                        border-color: #007bff; /* Change the color as per your design */
                        background-color: rgba(0, 123, 255, 0.05);
                        transition: all 0.9s ease;
                    }

                    .drag-drop-area.dragging:after {
                        width: 100%;
                        content: "Drop here";
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        top: 50%;
                        align-items: center;
                        left: 50%;
                        border-radius: 10px;
                        transform: translate(-50%, -50%);
                        color: #007bff;
                        background-color: #ebf5ff;
                        font-size: 24px;
                        height: 100%;
                        font-weight: bold;
                        transition: background-color 0.9s ease;
                    }
                    
                    
                      `}</style>
      <div className="flex flex-col gap-2 w-[100%] ">
        <div className="flex flex-col items-center justify-center gap-2.5 px-4 py-2 shadow-sm">
          {/* <span className="text-gray-900 text-sm font-medium">
            
          </span> */}

          <label htmlFor="file-upload" className="w-[100%]">
            <button
              type="button"
              className={`flex flex-col items-center h-[65px] justify-center bg-gray-50 border-2 border-dashed border-gray-400 rounded-lg p-4 drag-drop-area ${
                dragging ? "dragging" : ""
              }`}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={() => document.getElementById("file-upload").click()}
            >
              <input
                type="file"
                id="file-upload"
                className="hidden"
                accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChange}
                disabled={uploading}
              />
              {file ? (
                <div className="flex flex-col gap-2 items-center">
                  <span className="text-md font-bold text-gray-700">
                    <span className="text-gray-500 font-semibold text-sm">
                      Selected File:
                    </span>{" "}
                    {file.name}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-2 cursor-pointer text-gray-500">
                  <Upload className="w-5 h-5" />
                  <span className="text-gray-500 text-sm">
                    Drag cost list to upload
                  </span>
                </div>
              )}
            </button>
          </label>
        </div>
      </div>
    </>
  );
};

const StateIDS = ({ binded, change, data }) => {
  useEffect(() => {
    if (binded?.state_id) {
      // change([...data?.state_ids , binded?.state_id?.data ]
      change(data?.state_ids.concat(binded?.state_id?.data), "state_ids");
      change(null, "state_id");
      change(null, "country_id");
    }
  }, [binded?.state_id]);

  // Handle form submit.

  return (
    data?.state_ids?.length > 0 && (
      <div className="flex flex-col">
        <div className="w-[100%] grid grid-cols-2 gap-2">
          <style>
            {`
          .fadeOut {
            transition: opacity 500ms ease-out;
            opacity: 0;
          }
        `}
          </style>
        </div>
        <div className=" flex items-start flex-wrap py-2 px-5 gap-1 space-y-1 overflow-y-auto">
          {data?.state_ids?.length > 0 &&
            data?.state_ids?.map((item, index) => (
              <div
                key={item?.id}
                className="h-[34px] !mt-0 p-2 gap-3 bg-gray-50 rounded-lg justify-between items-center inline-flex"
              >
                <div className="justify-center items-center gap-2.5 flex">
                  <img
                    alt="flag"
                    src={item?.country?.flag}
                    className="w-[25.2px] h-[18px] rounded-sm"
                  />
                  <div className="text-[#111928] text-[12px] leading-[18px] font-normal font-['Inter'] whitespace-nowrap">
                    {item.name}
                  </div>
                </div>
                {descriptiveContent(
                  <HiTrash
                    className="w-3 h-3 text-gray-400 hover:text-red-400   cursor-pointer"
                    onClick={() => {
                      change(
                        data?.state_ids.filter((state) => state.id !== item.id),
                        "state_ids"
                      );
                    }}
                  />,
                  "Delete state"
                )}
              </div>
            ))}
        </div>
      </div>
    )
  );
};

Upload.propTypes = {
  change: PropTypes.func.isRequired,
};

StateIDS.propTypes = {
  binded: PropTypes.object,
  change: PropTypes.func.isRequired,
  data: PropTypes.object,
};

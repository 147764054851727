import callEndpoint from "../../dynamic-page/util/callEndpoint";
import FullScreenModal from "../../dynamic-page/FullScreenModal";
import StoreConnect from "./store-connect";
import localforage from "localforage";
import DownloadDocs from "./files";

const actionList = {
    add: {
        label: "Add Product",
        onClick: ({forms, data}) => {
            forms?.["add"].open(data);
        },
        condition: false,
    },

    edit: {
        label: "Edit",
        onClick: ({forms, actions, data}) => {
            forms?.["add"].open(data?.id ? data : data[0]);
        },
        bulkDisable: true,
    },

    activate: {
        label: "Activate",
        onClick: ({
                      data,
                      refreshPage,
                      setConfirmationModalVisibility,
                      replaceRow,
                  }) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to activate the selected account/s?",
                confirmText: "Yes, Activate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Activate Accounts",
                        method: "patch",
                        url: `accounts/status/bulk-action`,
                        data: {
                            status: 1,
                            accounts: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            if (ids?.length === 1) {
                                replaceRow(data?.id, {...data, status: 1});
                            } else {
                                refreshPage();
                            }
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 0;
        },
    },

    deactivate: {
        label: "Deactivate",
        onClick: ({
                      data,
                      refreshPage,
                      setConfirmationModalVisibility,
                      replaceRow,
                  }) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to deactivate the selected account/s?",
                confirmText: "Yes, Deactivate",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Deactivate Accounts",
                        method: "patch",
                        url: `accounts/status/bulk-action`,
                        data: {
                            status: 0,
                            accounts: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            if (ids?.length === 1) {
                                replaceRow(data?.id, {...data, status: 0});
                            } else {
                                refreshPage();
                            }
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 1;
        },
    },

    delete: {
        label: "Delete",
        onClick: ({
                      data,
                      refreshPage,
                      setConfirmationModalVisibility,
                      removeRow,
                  }) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to delete the selected account/s?",
                confirmText: "Yes, Delete",
                callBack: (setSubmit) => {
                    const ids = data?.id ? [data?.id] : data?.map((item) => item?.id);
                    callEndpoint({
                        title: "Delete Accounts",
                        method: "delete",
                        url: `accounts/delete/bulk-action`,
                        data: {
                            accounts: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            removeRow(ids);
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status !== 2;
        },
    },

    restore: {
        label: "Restore",
        onClick: ({data, refreshPage, setConfirmationModalVisibility}) => {
            setConfirmationModalVisibility({
                infoText: "Are you sure you want to restore the selected account/s?",
                confirmText: "Yes, Restore",
                callBack: (setSubmit) => {
                    const ids = data?.id;
                    callEndpoint({
                        title: "Restore Accounts",
                        method: "post",
                        url: `accounts/${ids}/restore`,
                        data: {
                            accounts: ids,
                        },
                        pureData: true,
                    }).then((res) => {
                        setSubmit(false);
                        if (res?.type === "success") {
                            setConfirmationModalVisibility(null);
                            refreshPage();
                        }
                    });
                },
            });
        },
        condition: (data) => {
            return data?.status === 2;
        },
        bulkDisable: true,
    },

    connect: {
        label: "Connect / Reconnect",
        onClick: ({forms, data, setVisibleExternalModal}) => {
            const onSubmit = async () => {
                callEndpoint({
                    title: "Connect Store",
                    method: "post",
                    url: `accounts/${data?.id}/authorize-store`,
                    data: {},
                })
                    .then(async (response) => {
                        if (response?.data) {
                            // Storing the state and internal_redirect_uri value in local storage
                            await localforage.setItem("oauthDetails", {
                                state: response?.data?.state,
                                internal_redirect_uri: response?.data?.internal_redirect_uri,
                            });
                        }
                        // Redirecting to Amazon for authorization
                        if (response?.data?.oauth_url) {
                            window.open(response?.data?.oauth_url, "_blank");
                        }
                    })
                    .finally(() => {
                        setVisibleExternalModal(null);
                    });
            };

            forms?.["connect"].open(
                data,
                <FullScreenModal
                    onClose={() => {
                        setVisibleExternalModal(false);
                    }}
                    className="w-screen h-screen my-auto"
                >
                    <StoreConnect item={data} onConnectApply={onSubmit}/>
                </FullScreenModal>
            );
        },
        bulkDisable: true,
        condition: (data) => data?.channel?.marketplace?.id === 2,
    },

    downloadDocs: {
        label: "Download Docs",
        onClick: ({data}) => {
            const url = `https://api-test.service.arbitbox.com/api/v1/accounts/${data?.id}/documents/download`;

            // Create an anchor element to trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = "account-documents.zip"; // Specify the filename
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            // }
            // });
        },
        bulkDisable: true,
        condition: (data) => data?.account_documents?.length > 0,
    },

    download: {
        label: "Download",
        onClick: ({
                      forms,
                      data,
                      setVisibleExternalModal,
                      extra,
                      refreshPage,
                      replaceRow,
                      ...rest
                  }) => {

            forms?.["download"].open(
                data,
                <DownloadDocs
                    onClose={(items) => {
                        setVisibleExternalModal(false);
                        // if (items?.length !== data?.documents_count) refreshPage();
                        if (items) {
                            if (items?.meta?.total !== data?.documents_count) {
                                replaceRow(data?.id, {
                                    ...data,
                                    documents_count: items?.meta?.total,
                                });
                            }
                        }
                    }}
                    id={data?.id}
                    refreshPage={refreshPage}
                />
            );
        },
        condition: false,
    },

    save: {
        label: "Save",
        onClick: ({data, refreshPage, close, callBack, clear, replaceRow}) => {
            callEndpoint({
                title: "Save Account",
                method: "post",
                url: data?.id ? `accounts/${data?.id}` : `accounts`,
                data: data,
                pureData: true,
            }).then((response) => {
                
                if (response?.type === "success") {
                    callBack();
                    close();
                    clear();
                    replaceRow(data?.id, response?.data);
                }
            });
        },
        condition: false,
    },
    saveAndConnect: {
        label: "Save",
        onClick: ({
                      data,
                      refreshPage,
                      close,
                      callBack,
                      clear,
                      actions,
                      forms,
                      setVisibleExternalModal,
                  }) => {
            // if (data?.proxy_id) {
            //   delete data?.proxy_id;
            // }

            // if (data?.identity_documents) {
            //   if (!Array.isArray(data.identity_documents)) {
            //     data.identity_documents = [data.identity_documents];
            //   }
            // } else {
            //   delete data.identity_documents;
            // }

            // if (!data?.shipstation_store_id) {
            //   data.shipstation_store_id = "";
            // }
            // if (!data?.shipentegra_store_id) {
            //   data.shipentegra_store_id = "";
            // }

            // if (!data?.note) {
            //   delete data.note;
            // }

            callEndpoint({
                title: "Save Account",
                method: "post",
                url: data?.id ? `accounts/${data?.id}` : `accounts`,
                data: data,
                pureData: true,
            }).then((response) => {
                callBack();
                if (response?.type === "success") {
                    // actions?.connect?.onClick({ forms, data: response?.data , setVisibleExternalModal })

                    const onSubmit = async () => {
                        callEndpoint({
                            title: "Connect Store",
                            method: "post",
                            url: `accounts/${response?.data?.id}/authorize-store`,
                            data: {},
                        })
                            .then((response) => {
                                if (response?.data) {
                                    // Storing the state and internal_redirect_uri value in local storage
                                    localforage.setItem("oauthDetails", {
                                        state: response?.state,
                                        internal_redirect_uri: response?.internal_redirect_uri,
                                    });
                                }
                                // Redirecting to Amazon for authorization
                                if (response?.data?.oauth_url) {
                                    window.open(response?.data?.oauth_url, "_blank");
                                }
                            })
                            .finally(() => {
                                setVisibleExternalModal(null);
                            });
                    };

                    forms?.["connect"].open(
                        response?.data,
                        <FullScreenModal
                            onClose={() => {
                                setVisibleExternalModal(false);
                                refreshPage();
                            }}
                            className="w-screen h-screen my-auto"
                        >
                            <StoreConnect item={response?.data} onConnectApply={onSubmit}/>
                        </FullScreenModal>
                    );

                    close();
                    clear();
                    // refreshPage();
                }
            });
        },
        condition: false,
    },
};

export default actionList;

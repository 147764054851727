import React, { useState } from "react";
import {
  HiArrowLeft,
  HiCheck,
  HiExternalLink,
  HiInformationCircle,
} from "react-icons/hi";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";

import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";

import classNames from "classnames";
import ArbitToggle from "../../sub-elements/toggle";
import ListingSales from "../components/listingSales";
import InternalNote from "../components/internalNote";
import Item from "../components/item";
import descriptiveContent from "../../../utils/descriptiveContent";
import SalesChannel from "../../dynamic-page/components/SalesChannel";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshAccountListings,
  updateRow,
} from "../../../store/fc-restocking";
import {
  closeNotification,
  startNotification,
} from "../../dynamic-page/util/notifierLive";
import RefreshSales from "../../dynamic-page/components/RefreshSales";
import PopupUp from "../../dynamic-page/util/popUp";
import Button from "../../dynamic-form/elements/Button";
import PropTypes from "prop-types";
import quantityHandler from "../../../utils/quantityHandler";
import shortenString from "../../dynamic-page/util/shortenString";

const TableView = (
  list,
  fulfilmentHandler,
  openSalesHistoryModal,
  openListingModal,
  handleTableFilters
) => {
  const pageData = useSelector((state) => state.fcRestocking);

  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const QuantityTooltip = ({ data }) => {
    if (data?.length > 0)
      return (
        <PopupUp
          toggle={(selected) => (
            <HiInformationCircle className="w-3 h-3 text-gray-300 cursor-pointer" />
          )}
          action="hover"
          // leftSpace={style?.leftSpace}
        >
          <div className="min-w-[250px] h-[55px]  bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
            {data?.map((item) => {
              return (
                <div
                  key={item?.account?.name}
                  className="flex flex-row justify-between w-full"
                >
                  <p className="text-gray-900 text-sm font-normal leading-[21px]">
                    {item?.account?.name}
                  </p>
                  <p className="text-gray-900 text-sm font-semibold leading-[21px]">
                    {item?.val || 0}
                  </p>
                </div>
              );
            })}
          </div>
        </PopupUp>
      );
  };
  QuantityTooltip.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        account: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired, // data must be an array of specific objects and is required
  };

  // create a higher order component to that return the tooltip
  const Tooltip = ({ title, value }) => {
    return (
      <PopupUp
        toggle={(selected) => (
          <Icons.Calculator className="w-3 h-3 text-gray-300 cursor-pointer" />
        )}
        action="hover"
        // leftSpace={style?.leftSpace}
      >
        <div className=" h-[55px]  bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
          <div className="text-center text-gray-900 text-sm font-medium leading-[14px]">
            {title}
          </div>
          <div className="text-gray-500 text-xs font-normal leading-[15px]">
            {value}
          </div>
        </div>
      </PopupUp>
    );
  };
  Tooltip.propTypes = {
    title: PropTypes.string.isRequired, // Ensure title is a required string
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Ensure value is a required string or number
  };

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const [refreshing, setRefreshing] = useState("");

  const dispatch = useDispatch();
  const handleRefreshListings = async (item2) => {
    const identifiers = [item2?.sales_channel_listing?.identifier];

    const ids = item2?.id;

    try {
      setRefreshing(item2?.id);

      let a = startNotification({
        title: `Refreshing the Listing`,
      });

      dispatch(refreshAccountListings({ identifiers }))
        .unwrap()
        .then((rs) => {
          setRefreshing("");
          closeNotification(a, {
            toast: {
              type: rs.type === "validation_error" ? "error" : rs.type,
              message: rs.message,
              errors: rs.errors,
            },
          });
          if (rs.type === "success") {
            identifiers.forEach((identifier) => {
              const row = rs.data.find(
                (item) => item.identifier === identifier
              );
              const originalRow = pageData?.fcRestockings?.find(
                (item) => item.sales_channel_listing.identifier === identifier
              );
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${originalRow.id}]`,
                  value: {
                    ...originalRow,
                    sales_channel_listing: row,
                    _updatedRow: true,
                  },
                })
              );
            });

            setTimeout(() => {
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${ids}]._updatedRow`,
                  value: false,
                })
              );
            }, 3000);
          }
        });
    } catch (error) {
      // Handle errors if the refresh fails
      console.error("Error refreshing account listings:", error);
    }
  };

  return {
    list: list,
    theme: {
      notes: (item) => (
        <div className={"flex"}>
          <div className={""}>
            <PopupUp
              className="!z-[9]"
              portal={true}
              toggle={(selected) => (
                <div className={"relative"}>
                  <Button
                    item={{
                      buttonType: "flat",
                      className: `p-[2px]  ${activeStyle(
                        item?.notes?.length > 0
                      )}`,
                    }}
                  >
                    <Icons.InternalNote className="w-5 h-5" />
                  </Button>
                  {item?.notes?.length > 0 && (
                    <div
                      className={
                        "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                      }
                    >
                      {item?.notes?.length}
                    </div>
                  )}
                </div>
              )}
              // leftSpace={style?.leftSpace}
            >
              <div className={"w-[525px]"}>
                <InternalNote
                  callBack={null}
                  notes={item?.notes}
                  id={item?.id}
                />
              </div>
            </PopupUp>
            {!item?.sales_channel_listing?.available ? (
              <PopupUp
                toggle={(selected) => (
                  <Icons.ExclamationFill className="w-[20px] h-[20px] text-red-600" />
                )}
                action="hover"
              >
                <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                  Unavailable
                </div>
              </PopupUp>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      channel: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];

        const modalListing = item;

        return (
          <div className="flex flex-col gap-2 w-full">
            <SalesChannel
              item={{
                sold_by_us: item?.sold_by_us,
                channel: {
                  logo: item?.account?.["channel"]?.marketplace?.logo,
                  name: item?.account?.["channel"]?.name,
                  url: item?.account?.["channel"]?.url,
                },
                identifier_type: salesListing?.identifier_type,
                identifier_value: salesListing?.identifier,
                identifier: salesListing?.identifier,
                url: salesListing?.url,
              }}
              item2={item}
              refreshing={refreshing}
              handleRefreshListings={handleRefreshListings}
              date={salesListing?.updated_at}
              openListingModal={() => openListingModal(modalListing)}
            />
            <RefreshSales
              handleRefreshListings={() => handleRefreshListings(item)}
              item={item}
              refreshing={refreshing}
              date={salesListing?.updated_at}
            />
          </div>
        );
      },

      listing: (item) => (
        <div className="flex w-full">
          <div className="p-1">
            <div
              className={
                "min-w-[96px] max-w-[96px] h-[96px] max-h-[96px] p-1 justify-center items-center flex"
              }
            >
              {item?.sales_channel_listing?.main_image_path ? (
                <ImageMagnifier
                  src={item?.sales_channel_listing?.main_image_path}
                  className={"min-w-[96px] h-[96px] max-h-[96px] "}
                />
              ) : (
                <img
                  alt="def"
                  src={"/assets/images/noImage.png"}
                  className={"h-[52px] w-[52px]"}
                />
              )}
            </div>
          </div>
          <Item
          product={
            item?.sales_channel_listing
              ? [item?.sales_channel_listing?.product]
              : [item?.sales_channel_listings[0]?.product]
          }
          item={item}
          handleTableFilters={handleTableFilters}
          pageData={pageData}
        />
        </div>
      ),

      "LISTING ANALYSIS": (item) => {
        const tooltip = item?.tooltips?.wh_qty?.[0]?.account?.account_id;
        item = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings[0];
        const offerCount = item?.total_offer_count;

        return (
          <div className="flex flex-col gap-1 w-[200px] !px-[8px] !py-[12px] ">
            <div className="flex items-center justify-between">
              <PopupUp
                toggle={(selected) => (
                  <p
                    className={classNames(
                      item?.["buybox_price"]
                        ? "text-[#047481] font-[600]"
                        : "text-[#7EDCE2] font-[500]",
                      "text-[24px] leading-[24px]  flex items-center gap-1"
                    )}
                  >
                    {item?.["buybox_price"]
                      ? "$" + priceHandler(item?.["buybox_price"])
                      : "No BB"}
                  </p>
                )}
                action="hover"
              >
                <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                  Buybox Price
                </div>
              </PopupUp>

              <div className="flex flex-col justify-end ">
                <div className="  justify-between items-center gap-2.5 inline-flex">
                  <div className="flex items-center gap-0.5">
                    {tooltip === item?.buybox_owner_seller_id && (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.30144 1.89938C4.71353 1.42547 5.32166 1.125 6 1.125C6.67831 1.125 7.28641 1.42544 7.6985 1.89931C8.32505 1.85553 8.96761 2.07308 9.44731 2.55279C9.92702 3.03249 10.1446 3.67504 10.1008 4.3016C10.5746 4.71368 10.875 5.32174 10.875 6C10.875 6.67838 10.5745 7.28655 10.1005 7.69864C10.1442 8.32509 9.92665 8.96751 9.44703 9.44713C8.96741 9.92675 8.32499 10.1443 7.69854 10.1006C7.28646 10.5745 6.67834 10.875 6 10.875C5.3217 10.875 4.7136 10.5746 4.30152 10.1007C3.67496 10.1445 3.0324 9.92694 2.55269 9.44723C2.07298 8.96752 1.85543 8.32496 1.89921 7.69841C1.42539 7.28632 1.125 6.67826 1.125 6C1.125 5.32171 1.42542 4.71361 1.89928 4.30153C1.85555 3.67502 2.0731 3.03254 2.55277 2.55287C3.03244 2.0732 3.67493 1.85565 4.30144 1.89938ZM7.80515 5.09296C7.92553 4.92443 7.88649 4.69023 7.71796 4.56985C7.54943 4.44947 7.31523 4.48851 7.19485 4.65704L5.57715 6.92182L4.76516 6.10984C4.61872 5.96339 4.38128 5.96339 4.23484 6.10984C4.08839 6.25628 4.08839 6.49372 4.23484 6.64016L5.35984 7.76517C5.43778 7.84311 5.54607 7.88281 5.65593 7.87372C5.76579 7.86463 5.86608 7.80766 5.93015 7.71796L7.80515 5.09296Z"
                          fill="#1A56DB"
                        />
                      </svg>
                    )}
                    {item?.buybox_seller_type ? (
                      <PopupUp
                        toggle={(selected) => (
                          <div
                            className={`text-center text-[12px] !leading-3 ${
                              item?.buybox_seller_type === "FBM"
                                ? "text-blue-700"
                                : "text-orange-600"
                            } text-xs font-normal leading-3`}
                          >
                            {item?.buybox_seller_type}
                          </div>
                        )}
                        action="hover"
                      >
                        <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                          Buybox Owner Fulfillment Type
                        </div>
                      </PopupUp>
                    ) : (
                      ""
                    )}
                  </div>
                  <PopupUp
                    toggle={(selected) => (
                      <button
                        className="justify-between items-center gap-1 flex cursor-pointer"
                        onClick={() => {
                          window.open(
                            `https://www.amazon.com/gp/offer-listing/${item?.identifier}`,
                            "_blank"
                          );
                        }}
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 2.375C1 2.16789 1.16789 2 1.375 2H8.625C8.83211 2 9 2.16789 9 2.375C9 2.58211 8.83211 2.75 8.625 2.75H1.375C1.16789 2.75 1 2.58211 1 2.375ZM4.5 7.625C4.5 7.41789 4.66789 7.25 4.875 7.25H8.625C8.83211 7.25 9 7.41789 9 7.625C9 7.83211 8.83211 8 8.625 8H4.875C4.66789 8 4.5 7.83211 4.5 7.625Z"
                            fill="#7E3AF2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 5C1 4.79289 1.16789 4.625 1.375 4.625H8.625C8.83211 4.625 9 4.79289 9 5C9 5.20711 8.83211 5.375 8.625 5.375H1.375C1.16789 5.375 1 5.20711 1 5Z"
                            fill="#7E3AF2"
                          />
                        </svg>

                        <div className="text-purple-600 text-[12px] font-semibold leading-[12px] ">
                          {offerCount != null ? offerCount : "-"}
                        </div>
                      </button>
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Total Offers
                    </div>
                  </PopupUp>
                </div>
                <div className="flex justify-end">
                  <PopupUp
                    toggle={(selected) => (
                      <div className="flex justify-end">
                        <span className="text-[12px] leading-[12px] text-red-700 font-medium text-end">
                          {item?.["bb_low_price"]
                            ? "$" + priceHandler(item?.["bb_low_price"])
                            : "-"}
                        </span>
                      </div>
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Lowest Price
                    </div>
                  </PopupUp>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="flex items-start justify-between">
                <p
                  className={classNames(
                    item?.["category_rank_degree"]
                      ? "text-[#7E3AF2]"
                      : "text-purple-200",
                    "text-[24px] leading-[24px] font-[500]"
                  )}
                >
                  {item?.["category_rank_degree"]
                    ? item?.["category_rank_degree"]
                    : "-"}
                </p>
                <div className="flex flex-col justify-end">
                  <div className="flex items-start justify-end">
                    {item?.["sales_channel_top"] ? (
                      <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                        top
                      </p>
                    ) : (
                      ""
                    )}
                    &nbsp;
                    <PopupUp
                      toggle={(selected) => (
                        <div className="flex items-start gap-[2px]">
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.26316 1C1.56554 1 1 1.56554 1 2.26316V7.5C1 8.32843 1.67157 9 2.5 9C3.32843 9 4 8.32843 4 7.5V2.26316C4 1.56554 3.43446 1 2.73684 1H2.26316ZM7.26366 4.85764L4.75 7.3713V3.12866L5.14233 2.73632C5.63563 2.24303 6.43542 2.24303 6.92871 2.73632L7.26366 3.07127C7.75695 3.56456 7.75695 4.36435 7.26366 4.85764ZM4.18198 9H7.73683C8.43446 9 8.99999 8.43447 8.99999 7.73684V7.26316C8.99999 6.56554 8.43446 6 7.73684 6H7.18198L4.18198 9ZM2.5 8C2.77614 8 3 7.77614 3 7.5C3 7.22386 2.77614 7 2.5 7C2.22386 7 2 7.22386 2 7.5C2 7.77614 2.22386 8 2.5 8Z"
                              fill="#1A56DB"
                            />
                          </svg>
                          <span className="text-[12px] leading-[12px] font-medium text-blue-700 ">
                            {item?.variation_count || 1}
                          </span>
                        </div>
                      )}
                      action="hover"
                    >
                      <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        Variation Count
                      </div>
                    </PopupUp>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between ">
                <span className="text-gray-500 text-[12px] leading-[18px] ">
                  {item?.root_category
                    ? shortenString(
                        item?.root_category
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" "),
                        20
                      )
                    : "N/A"}
                </span>
                <PopupUp
                  toggle={(selected) => (
                    <p className="text-[12px] leading-[12px] text-[#B43403] font-medium text-end">
                      {item?.["sales_rank"]
                        ? "#" + quantityHandler(item?.["sales_rank"])
                        : "-"}
                    </p>
                  )}
                  action="hover"
                >
                  <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                    Sales Rank
                  </div>
                </PopupUp>
              </div>
            </div>
          </div>
        );
      },

      "SUGGESTED RESTOCKING": (item) => (
        <div className="w-[100%] flex justify-start">
          <ListingSales
            item={{
              weekly: {
                upper_title: "by 7 Days",
                upper_value: item?.["fba_7_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 7 days"
                    value={item?.tooltips?.["7_days_restocking"]}
                  />
                ),
                lower_title: "Days Left",
                lower_value: item?.["fba_7_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 7 days"
                    value={item?.tooltips?.["7_days_remaining"]}
                  />
                ),
                color: "gray-900",
              },
              monthly: {
                upper_title: "by 30 Days",
                upper_value: item?.["fba_30_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 30 days"
                    value={item?.tooltips?.["30_days_restocking"]}
                  />
                ),
                lower_title: "Days Left",
                lower_value: item?.["fba_30_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 30 days"
                    value={item?.tooltips?.["30_days_remaining"]}
                  />
                ),
                color: "red-700",
              },
              biMonthly: {
                upper_title: "by 60 Days",
                upper_value: item?.["fba_60_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 60 days"
                    value={item?.tooltips?.["60_days_restocking"]}
                  />
                ),
                lower_title: "Days Left",
                lower_value: item?.["fba_60_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 60 days"
                    value={item?.tooltips?.["60_days_remaining"]}
                  />
                ),
                color: "green-700",
              },
            }}
          />
        </div>
      ),

      supply: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];
        return (
          <div className="w-[100%] flex justify-start">
            <ListingSales
              item={{
                weekly: {
                  upper_title: "Requested",
                  upper_value: item?.["supply_request_count"],
                  upper_tooltip: (
                    <HiExternalLink
                      className="w-4 h-4 text-gray-300 cursor-pointer"
                      onClick={() => {
                        if (salesListing?.product?.id)
                          window.open(
                            `/inventory/supply_requests?term=&filter%5Bproduct_id%5D=${salesListing?.product?.id}&limit=20&page=1`,
                            "_blank"
                          );
                      }}
                    />
                  ),
                  lower_title: "Purchased",
                  lower_value: item?.["supply_purchase_count"],
                  lower_tooltip: (
                    <HiExternalLink
                      className="w-4 h-4 text-gray-300 cursor-pointer"
                      onClick={() => {
                        if (salesListing?.product?.id)
                          window.open(
                            `/inventory/supply_purchases?term=&filter%5Bproduct_id%5D=${salesListing?.product?.id}&limit=20&page=1`,
                            "_blank"
                          );
                      }}
                    />
                  ),
                  className: '!w-[105px] !p-4'
                },
                color: "purple",
              }}
            />
          </div>
        );
      },

      "Fc eligibility": (item) => (
        <div className="flex flex-col justify-between  !py-[12px] gap-[6px] h-full !w-[204px]">
          <div className="flex flex-row justify-between gap-4  w-full">
            <div className="flex flex-row items-center justify-center gap-1">
              {descriptiveContent(
                <ArbitToggle
                  status={item?.fba_eligibility ?? false}
                  bgColor={
                    item?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                  }
                  handleFulfilment={() => fulfilmentHandler("fba", item?.id)}
                />,
                "Toggle FC Eligibility"
              )}
              <p
                className={`${
                  item?.fba_eligibility ? "text-orange-600" : "text-gray-400"
                } leading-[17.5px] text-[14px] font-medium`}
              >
                FC Restock
              </p>
            </div>
            {descriptiveContent(
              <button
                className="flex flex-row items-center text-orange-600 gap-1 justify-center cursor-pointer"
                onClick={() => {
                  window.open(
                    `/listing/offers?search=&filter%5Bfulfillment%5D=AMAZON_NA&filter%5Bidentifier%5D=${
                      item?.sales_channel_listing?.identifier ||
                      item?.sales_channel_listings[0]?.identifier
                    }&page=1`,
                    "_blank"
                  );
                }}
              >
                <Icons.OfferCount />
                <p className="text-orange-600 text-[20px] leading-5 font-medium">
                  {nullHandle(item, item?.fba_offers_count)}
                </p>
              </button>,
              "Offers Count"
            )}
          </div>
          {item?.fba_eligibility ? (
            <div className="flex flex-row justify-between gap-4  w-full">
              <div className="flex flex-row items-center justify-center">
                <p className="text-orange-600 text-[16px] leading-6 font-inter font-medium">
                  {descriptiveContent(
                    item.fba_price ? `$` + priceHandler(item.fba_price) : "-",
                    "FBA Price"
                  )}
                </p>
              </div>
              <div className="flex flex-row items-center justify-center">
                <p className="text-orange-600 text-[16px] !leading-6 font-bold">
                  {descriptiveContent(
                    item?.fba_price_margin ? `${item?.fba_price_margin}%` : "-",
                    " FBA Margin"
                  )}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start justify-end w-full text-xl text-gray-500 font-medium leading-5 break-normal ">
              <p className="p-0 m-0">Not Eligible. </p>
            </div>
          )}
          <div className="flex flex-row justify-between gap-1 items-center w-full">
            <HiCheck className="h-[15px] w-[18px]" color="#B43403" />
            <p className="text-[#B43403] text-[16px] leading-6 font-inter font-medium ">
              {descriptiveContent(
                item.fba_available_qty,
                "FBA Available Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.available} />
            <HiArrowLeft className="h-[11px] w-[15px]" color="#FDBA8C" />
            <p className="text-[#B43403] text-[16px] leading-6 font-inter font-normal ">
              {descriptiveContent(
                item.fba_reserved_qty ?? "N/A",
                "FBA Reserved Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.reserved_qty} />
            <HiArrowLeft className="h-[11px] w-[15px]" color="#FDBA8C" />
            <p className="text-[#B43403] text-[16px] leading-6 font-inter font-normal ">
              {descriptiveContent(
                item.fba_inbound_quantity ?? "N/A",
                "FBA Inbound Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.inbound_qty} />
          </div>
        </div>
      ),
    },
  };
};
export default TableView;

// Import General libraries
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";
import { createPortal } from "react-dom";

// Main Component: PopupUp
const PopupUp = forwardRef(
  (
    {
      toggle,
      children,
      action = "click",
      forcePosition,
      className,
      innerClassName,
      outerClassName,
      tabIndex,
      leftSpace,
      rightSpace,
      topSpace,
      bottomSpace,
      toggleClassName,
      delay = 0,
      close,
      portal = false,
    },
    ref
  ) => {
    // Initial State to handle menu open and close.
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      close: () => {
        setIsOpen(false);
      },
    }));

    const modalRef = useRef(null);
    const toggleButtonRef = useRef();
    const menuRef = useRef();
    const arrowRef = useRef();

    const repositionPopup = useCallback(() => {
      if (!isOpen || !menuRef.current || !toggleButtonRef.current) return;

      const toggleButtonRect = toggleButtonRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const buttonSize = {
        w: toggleButtonRect.width,
        h: toggleButtonRect.height,
      };

      const availableSpace = {
        top: toggleButtonRect.top,
        bottom: viewportHeight - toggleButtonRect.bottom,
        left: toggleButtonRect.left,
        right: viewportWidth - toggleButtonRect.right,
      };

      const requiredSpace = {
        width: menuRect.width / 2,
        height: menuRect.height,
      };

      const calculateXShift = () => {
        if (
          availableSpace.right < requiredSpace.width &&
          availableSpace.left >= requiredSpace.width
        ) {
          return requiredSpace.width - availableSpace.right + 10;
        } else if (
          availableSpace.left < requiredSpace.width &&
          availableSpace.right >= requiredSpace.width
        ) {
          return -(requiredSpace.width - availableSpace.left);
        }
        return 0;
      };

      const shouldShiftUp = () => {
        const forceTop = forcePosition === "top";
        const insufficientBottomSpace =
          availableSpace.bottom < requiredSpace.height + 90;
        const sufficientTopSpace = availableSpace.top >= requiredSpace.height;
        return forceTop || (insufficientBottomSpace && sufficientTopSpace);
      };

      const xShift = calculateXShift();
      const needShiftUp = shouldShiftUp();
      let yShift = 0;

      if (needShiftUp) {
        yShift = -requiredSpace.height - buttonSize.h - 4;
        menuRef.current.classList.add("flex-col-reverse");
      } else {
        menuRef.current.classList.remove("flex-col-reverse");
      }

      if (arrowRef.current) {
        arrowRef.current.style.transform = needShiftUp ? "rotate(180deg)" : "";
        arrowRef.current.style.left = `${xShift}px`;
      }

      if (portal) {
        // Positioning when using portal (fixed positioning relative to viewport)
        const top = toggleButtonRect.bottom + yShift;
        const left =
          toggleButtonRect.left +
          buttonSize.w / 2 -
          requiredSpace.width -
          xShift;
        menuRef.current.style.top = `${top}px`;
        menuRef.current.style.left = `${left}px`;
      } else {
        // Positioning when not using portal (relative to parent)
        const xCenter = -requiredSpace.width + buttonSize.w / 2;
        const position = {
          top: 4 + buttonSize.h,
          left: 0,
        };
        menuRef.current.style.top = `${position.top + yShift}px`;
        menuRef.current.style.left = `${xCenter + position.left - xShift}px`;
      }
    }, [isOpen, portal, forcePosition]);

    useEffect(() => {
      if (!isOpen) return;

      const handleClickOutside = (event) => {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target) &&
          menuRef.current &&
          !menuRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      const handleScroll = () => {
        if (action === "hover") {
          setIsOpen(false);
        } else if (action === "click") {
          repositionPopup(); // Update popup position on scroll
        }
      };

      // Add event listener when component is mounted and menu is open
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll, true); // Use capture phase to catch scroll events

      // Remove event listener when component unmounts or menu closes
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener("scroll", handleScroll, true);
      };
    }, [isOpen, action, repositionPopup]);

    let timeoutId;

    const handleHide = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (
          !toggleButtonRef.current.contains(event.target) &&
          event.target.tagName !== "BODY"
        ) {
          document.removeEventListener("click", handleHide);
          setIsOpen(false);
        }
      }
    };

    const handleShow = () => {
      if (!isOpen) document.addEventListener("click", handleHide);
      setIsOpen(!isOpen);
    };

    const handleMouseLeave = () => {
      if (action === "hover") {
        clearTimeout(timeoutId);
        setIsOpen(false);
      }
    };

    const handleMouseEnter = () => {
      if (action === "hover") {
        timeoutId = setTimeout(() => {
          setIsOpen(true);
          clearTimeout(timeoutId);
        }, delay);
      }
    };

    useEffect(() => {
      repositionPopup();
    }, [isOpen, portal, forcePosition, repositionPopup]);

    useEffect(() => {
      if (!menuRef.current) return;
      let observer;

      if (typeof ResizeObserver !== "undefined") {
        observer = new ResizeObserver(() => {
          repositionPopup();
        });

        observer.observe(menuRef.current);
      }

      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }, [isOpen, repositionPopup]);

    const renderMenu = () =>
      isOpen && (
        <div
          ref={menuRef}
          className={`popOverWindow transition ${
            portal ? "fixed" : "absolute"
          } w-max z-[99999] flex flex-col justify-center text-left rounded-[4px] w-content overflow-hidden ${className}`}
          style={{ position: portal ? "fixed" : "absolute", top: 0, left: 0 }}
        >
          <div
            className={`flex justify-center items-center text-white relative w-full h-[8px]`}
            ref={arrowRef}
          >
            <div className={"h-[8px] w-[20px] absolute top-0"}>
              <svg
                className={"relative"}
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 8"
                style={{ enableBackground: "new 0 0 18 8" }}
              >
                <path d="m9.7.7 7 7c.2.2.4.3.7.3H.6c.3 0 .5-.1.7-.3l7-7c.4-.4 1-.4 1.4 0z" />
              </svg>
            </div>
          </div>
          <div
            className={`whitespace-pre-wrap shadow-md bg-white p-[8px] rounded-md overflow-hidden ${innerClassName}`}
          >
            {children}
          </div>
        </div>
      );

    // Render PopupUp
    return (
      <div
        className={`flex items-center ${outerClassName ?? ""} popOver-${
          isOpen ? "open" : "closed"
        } ${portal ? "" : "relative"}`}
        ref={modalRef}
      >
        <button
          ref={toggleButtonRef}
          type="button"
          className={`cursor-pointer select-text ${toggleClassName}`}
          onClick={handleShow}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          aria-haspopup="true"
          aria-expanded={isOpen}
          tabIndex={tabIndex}
        >
          {toggle(isOpen || false)}
        </button>

        {portal ? createPortal(renderMenu(), document.body) : renderMenu()}
      </div>
    );
  }
);

PopupUp.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  action: PropTypes.oneOf(["click", "hover"]),
  forcePosition: PropTypes.oneOf(["top", "bottom"]),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  outerClassName: PropTypes.string,
  tabIndex: PropTypes.number,
  leftSpace: PropTypes.number,
  rightSpace: PropTypes.number,
  topSpace: PropTypes.number,
  bottomSpace: PropTypes.number,
  toggleClassName: PropTypes.string,
  delay: PropTypes.number,
  close: PropTypes.func,
  portal: PropTypes.bool, // Added portal prop type
};

export default PopupUp;

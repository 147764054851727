import React, { memo, useRef, useState } from "react";
import MainModal from "../../../dynamic-page/MainModal";
import PropTypes from "prop-types";
import notifier from "../../../dynamic-page/util/notifier";
import useCreateShipmentInitializer from "./createShipmentInitializer";
import formView from "./model-view/formView";
import tableView from "./model-view/table";
import BulkShipmentView from "./BulkShipmentView";
import Button from "../../../dynamic-form/elements/Button";

function toggle(
  {states,
  items,
  loading,
  errors,
  hideDelete,
  handleList,
  handleSort,
  myFormRef,
  formView,
  tableView,
  cancel,
  handleSubmit,
  refresh}
) {
  return () => (
    <BulkShipmentView
      states={states}
      items={items}
      loading={loading}
      errors={errors}
      hideDelete={hideDelete}
      handleList={handleList}
      handleSort={handleSort}
      myFormRef={myFormRef}
      formView={formView}
      tableView={tableView}
      cancel={cancel}
      handleSubmit={handleSubmit}
      refresh={refresh}
    />
  );
}

const BulkCreateShipment = memo(({ orders, cancel, submit, refresh }) => {
  const myFormRef = useRef(null);

  const [items, setItems] = useState(orders);
  const [states, setStates] = useState({});
  const [loading, setLoading] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hideDelete, setHideDelete] = useState(false);

  // Add a state to track the sort direction
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'

  const updateItemWithCarrier = (
    item,
    orderId,
    { carrierId, cost, code, refresh, fresh }
  ) => {
    if (item.id !== orderId) return item;
    return {
      ...item,
      ...(carrierId !== undefined && { selectedCarrierId: carrierId }),
      ...(code !== undefined && { code }),
      ...(cost !== undefined && { selectedCarrierCost: cost }),
      fresh: fresh ?? item.fresh ?? 0,
      refresh: refresh ?? item.refresh ?? false,
    };
  };

  const updateItems = (prevItems, orderId, carrierData) => {
    return prevItems.map((item) =>
      updateItemWithCarrier(item, orderId, carrierData)
    );
  };

  const handleList = (value, bound) => {
    console.log("handleList", value, bound);
    setStates({
      ...states,
      warehouse: bound?.warehouse?.data || states?.warehouse,
      onWarehouse: bound?.onWarehouse?.data ,
      onUpdateCarrier: (orderId, carrierData) => {
        setItems((prevItems) => updateItems(prevItems, orderId, carrierData));
      },
    });
  };

  const { initializeShipment } = useCreateShipmentInitializer();

  const handleSubmit = async () => {
    const unselectedOrders = items.filter((item) => !item.selectedCarrierId);
    if (unselectedOrders.length > 0) {
      notifier({
        type: "info",
        message: `${unselectedOrders.length} order(s) don't have a carrier selected.`,
      });
      return;
    }

    const itemsToProcess = items.filter((item) => item.selectedCarrierId);

    if (itemsToProcess.length === 0) {
      notifier({
        type: "error",
        message: "Please select at least one carrier",
      });
      return;
    }

    setShowConfirmation(true);
  };

  const updateItemAfterShipment = (prevItems, itemId, data) => {
    return prevItems.map((prevItem) =>
      prevItem.id === itemId
        ? {
            ...prevItem,
            shipment_labels: data.data?.shipment_labels,
            _success: true,
          }
        : prevItem
    );
  };

  const processShipments = async () => {
    setHideDelete(true);
    const itemsToProcess = items.filter((item) => item.selectedCarrierId);

    setShowConfirmation(false);

    for (const item of itemsToProcess) {
      try {
        setLoading((prev) => ({ ...prev, [item.id]: true }));
        setErrors((prev) => ({ ...prev, [item.id]: null }));

        initializeShipment(
          {
            order_id: item.id,
            warehouse_id: states.warehouse?.id,
            shipment_pricing_id: item.selectedCarrierId,
          },
          (data) => {
            if (data?.type === "success") {
              setItems((prevItems) =>
                updateItemAfterShipment(prevItems, item.id, data)
              );
            } else {
              setErrors((prev) => ({
                ...prev,
                [item.id]: data?.message || "Failed to create shipment",
              }));
            }
            setLoading((prev) => ({ ...prev, [item.id]: false }));
          }
        );
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          [item.id]: error?.message || "Failed to create shipment",
        }));

        notifier({
          type: "error",
          message: `Failed to create shipment for order ${item.id}: ${error?.message}`,
        });
      }
    }
  };

  // Sort function to toggle between ascending and descending order
  const handleSort = (type) => {
    const sorted = items.slice().sort((a, b) => {
      if (sortDirection === "asc") {
        return a[type] - b[type]; // Ascending order
      } else {
        return b[type] - a[type]; // Descending order
      }
    });

    // Toggle the sort direction
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));

    // Update the state with the sorted array
    setItems(sorted);
  };

  return (
    <div className={""}>
      {showConfirmation && (
        <ConfirmationModal
          orderCount={items.filter((item) => item.selectedCarrierId).length}
          onCancel={() => setShowConfirmation(false)}
          onCreate={processShipments}
        />
      )}
      <MainModal
        className="!h-[602px] !w-[924px]  !min-w-[924px]"
        containerClassName="!px-0"
        headerClassName="!px-5 !py-4 !h-[74px] !items-center !justify-between"
        optionClassName="!gap-[28px]"
        item={{
          title: `Bulk Shipment Creation`,
          cancel: cancel,
          view: toggle({
            states,
            items,
            loading,
            errors,
            hideDelete,
            handleList,
            handleSort,
            myFormRef,
            formView,
            tableView,
            cancel,
            handleSubmit,
            refresh,
          }),
        }}
      />
    </div>
  );
});

BulkCreateShipment.propTypes = {
  orders: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default BulkCreateShipment;

const ConfirmationModal = ({ onCancel, onCreate, orderCount }) => {
  return (
    <div className="fixed z-[9999] inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white relative rounded-lg shadow-lg p-6 max-w-md w-full">
        <div className="absolute top-5 right-5">
          <button onClick={onCancel}>
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
        <p className="text-gray-600 mb-6">
          You are about to create bulk labels for {orderCount} orders, this
          action is not reversible. Please check again if you think you are
          wrong.
        </p>
        <div className="flex justify-end space-x-4">
          <Button
            item={{
              label: <div className={"flex flex-row"}>Cancel</div>,
              buttonType: "alt",
              className: "h-[41px] !w-[120px] !px-[16px]",
            }}
            onClick={onCancel}
          />
          <Button
            item={{
              label: <div className={"flex flex-row"}>Create Shipment</div>,
              buttonType: "primary",
              className: "h-[41px] !px-[16px]",
            }}
            onClick={onCreate}
          />
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  orderCount: PropTypes.number.isRequired,
};

import React from "react";
import { HiOfficeBuilding, HiShoppingCart } from "react-icons/hi";
import util from "../../dynamic-page/util";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import StatusSteps from "../../dynamic-page/elements/StatusSteps";
import removeDecimals from "../../../utils/removeDecimals";
import descriptiveContent from "../../../utils/descriptiveContent";
import ItemID from "../../dynamic-page/components/ItemID";
import StatusHistory from "../../goods-acceptance/components/SupplyStatusHistory";
import Icons from "../../orders/icons";
import ProductName from "../../dynamic-page/components/productName";

import PopupUp from "../../dynamic-page/util/popUp";
import upperCase from "../../dynamic-page/util/upperCaseString";

const TableView = (list, handleTableFilters) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };

  const handleSupplyCss = (item) => {
    if (item === "WEBSITE") {
      return "bg-blue-100 text-blue-700 ";
    }
    if (item === "WHOLESALER") {
      return "bg-green-100 text-green-700  ";
    }
    if (item === "MANUFACTURER") {
      return "bg-yellow-100 text-yellow-700  ";
    }
  };

  const handleImage = (item) => {
    const Globe = Icons.globeAlt;
    const Rectangle = Icons.rectangleGroup;
    const Wrench = Icons.wrench;
    if (item === "WEBSITE") {
      return <Globe className="w-3 h-3 " />;
    }
    if (item === "WHOLESALER") {
      return <Rectangle className="w-3 h-3 " />;
    }
    if (item === "MANUFACTURER") {
      return <Wrench className="w-3 h-3 " />;
    }
  };

  const Request = Icons.requestNote;

  return {
    list: list,
    theme: {
      note: (item) => (
        <div className=" flex items-center">
          <PopupUp
            toggle={() =>
              descriptiveContent(
                <div
                  className={`flex items-center justify-center transition rounded-full ${activeStyle(
                    item?.comment
                  )}`}
                >
                  <Request className="w-5 h-5  cursor-pointer" />
                </div>,
                "Internal Note",
                null,
                "top"
              )
            }
            action={"click"}
          >
            <div
              style={{ hyphens: "auto" }}
              className={
                "max-w-[200px] px-[8px] py-[4px] text-[14px] whitespace-pre-wrap break-all"
              }
            >
              {item?.comment ? (
                upperCase(item?.comment)
              ) : (
                <span className={"text-gray-400"}>note is empty!</span>
              )}
            </div>
          </PopupUp>
        </div>
      ),
      status: (item) => (
        <div className="relative">    
        <StatusSteps
          list={[
            {
              label: <HiShoppingCart className="w-5 h-5" />,
              status: "awaiting",
            },
            {
              label: <HiOfficeBuilding className="w-5 h-5" />,
              status:
                item?.supply_request?.delivery_status === "Delivered"
                  ? "completed"
                  : item?.supply_request?.delivery_status
                      ?.split(" ")[0]
                      ?.toLowerCase(),
            },
          ]}
          tooltip={
            <StatusHistory id={item?.id} url="supply-request" includes={""} />
            }
          />
        </div>
      ),
      id: (item) => {
        let title = "ORD"; // Default title
        let bgColor = "red-100"; // Default background color
        let textColor = "red-800"; // Default text color

        if (item?.reason === "warehouse_restocking") {
          title = "WHS";
          bgColor = "purple-100";
          textColor = "purple-800";
        } else if (item?.reason === "fulfilment_restocking") {
          title = "FLS";
          bgColor = "blue-100";
          textColor = "blue-800";
        }

        return (
          <div className=" flex-col justify-center items-center gap-1 inline-flex">
            <ItemID id={item.id} />
            {item?.reason === "order" && item?.related_reason?.length > 0 ? (
              <PopupUp
                toggle={() => (
                  <ArbitNormalBadge
                    onClick={() => {
                      window.open(
                        `/logistics/orders?filter[order_number]=${item?.related_reason[0]}`,
                        "_blank"
                      );
                    }}
                    className={` ${
                      item?.reason === "order" ? "underline" : ""
                    } !font-medium
                                      `}
                    title={title}
                    bgColor={bgColor}
                    textColor={textColor}
                  />
                )}
                action="hover"
              >
                <div className="flex flex-col gap-1 p-2 overflow-y-auto max-h-[300px]">
                  {item?.related_reason?.map((order, index) => (
                    <div
                      key={order}
                      className="flex justify-between items-center gap-2"
                    >
                      <span className="text-gray-700 font-medium">{order}</span>
                    </div>
                  ))}
                </div>
              </PopupUp>
            ) : (
              <ArbitNormalBadge
                className={` !font-medium 
                          `}
                title={title}
                bgColor={bgColor}
                textColor={textColor}
              />
            )}
          </div>
        );
      },
      "requested at": (item) => (
        <util.dateDisplay item={item} date={item?.created_at} />
      ),

      image: (item) => (
        <div
          className={
            "w-[52px] h-[52px] max-h-[52px] justify-center items-center flex"
          }
        >
          {item?.product?.image ? (
            <ImageMagnifier
              src={item?.product?.image}
              className={"!max-h-[52px]"}
            />
          ) : (
            <img
              alt="def"
              src={"/assets/images/noImage.png"}
              className={"h-[52px] w-[52px]"}
            />
          )}
        </div>
      ),
      product: (item) => (
        <ProductName
          showApid={true}
          hide={{ brandCopy: true }}
          item={item?.product}
          user={item?.product?.brand?.assigned}
          handleTableFilters={handleTableFilters}
        />
      ),
      "supply channel": (item) => (
        <div className="flex items-center gap-1">
          <div
            className={`w-6 h-6 rounded-full  flex justify-center items-center ${handleSupplyCss(
              item?.supplier?.supply_channel_type
            )}`}
          >
            {handleImage(item?.supplier?.supply_channel_type)}
          </div>
          {item?.supplier
            ? descriptiveContent(
                <button
                  className={`flex  justify-start text-base font-medium items-center gap-1 max-w-[164px] ${
                    item?.url ? "cursor-pointer" : "cursor-default"
                  }`}
                  onClick={() => {
                    item?.url && window.open(item?.url, "_blank");
                  }}
                >
                  <span className=" truncate max-w-[128px] text-base font-medium">
                    {item?.supplier?.name}
                  </span>
                </button>,
                item?.supplier?.name.length > 17 && item?.supplier?.name
              )
            : ""}
        </div>
      ),
      "max. price": (item) => (
        <div className=" w-[100%]  justify-end items-start gap-0.5 inline-flex">
          <div className="justify-end items-center gap-0.5 inline-flex">
            <div className="text-[#046C4E] text-[18px] font-bold leading-[27px]">
              {item?.max_acceptable_price
                ? priceHandler(
                    item?.max_acceptable_price?.[
                      item?.max_acceptable_price_currency?.code
                    ],
                    item?.max_acceptable_price_currency
                  )
                : "-"}
            </div>
          </div>
        </div>
      ),
      qty: (item) => (
        // <div className="w-[79px] h-16 px-4 py-3   flex-col justify-center items-end gap-0.5 inline-flex">
        <div className="justify-center items-center  gap-1 inline-flex flex-col">
          <div className="text-[#6C2BD9] text-[18px]  font-bold leading-[27px] flex items-center gap-1">
            <span>{removeDecimals(item?.qty)}</span>
          </div>
        </div>
        // </div>
      ),
    },
  };
};
export default TableView;

import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";
import { fetchOffer, updateRow } from "../../../store/offers";
import { useDispatch } from "react-redux";
import { connectProduct } from "../../../store/offers/thunk";
import descriptiveContent from "../../../utils/descriptiveContent";
import ConnectProductModal from "../../dynamic-page/components/ConnectForm";
import { SalesChannelItem } from "../../sales-listing/modals-view/form";
import TruncateText from "../../dynamic-page/util/truncateText";
import CopyText from "../../dynamic-page/util/copyText";
import shortenString from "../../dynamic-page/util/shortenString";
import PropTypes from "prop-types";
import { HiFilter } from "react-icons/hi";

const mapListings = (item) => ({
  id: item?.id,
  identifier: item?.sales_channel_listing?.identifier,
  url: item?.sales_channel_listing?.url,
  label: item?.sales_channel_listing?.name,
  channel: item?.sales_channel_listing?.channel?.name,
  brand: item?.sales_channel_listing?.brand,
  image: item?.sales_channel_listing?.main_image_path,
});

const Item = ({ product, offer, onFilterApply, pageData }) => {
  const productList = product[0];
  const dispatch = useDispatch();

  const Link = Icons.link;

  return (
    <div className={" gap-[0px] flex flex-col !w-full"}>
      <div
        className={
          "flex flex-row gap-[8px] whitespace-pre-wrap   justify-between"
        }
      >
        <TruncateText
          maxLines={2}
          className={"text-[14px]"}
          tooltip={true}
          innerClassName={"text-start"}
        >
          {productList?.title ||
            offer?.sales_channel_listing?.name ||
            offer?.title}
        </TruncateText>
      </div>
      <div className={"flex justify-between"}>
        <div className="flex items-center gap-1">
          {descriptiveContent(
            <div className={"flex flex-row gap-[4px] text-gray-600"}>
              {shortenString(offer?.sku, 30)}
            </div>,
            offer?.sku?.length > 30 ? null : "SKU"
          )}
          <CopyText className={"text-[9px]"} text={offer?.sku} hint={false} />
        </div>

        {productList ? (
          <div className="flex items-center gap-[2px] !text-[12px]">
            <span
              className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${
                productList?.type === 1
                  ? "text-teal-700 bg-teal-100"
                  : "text-indigo-700 bg-indigo-100"
              } p-[0px]`}
            >
              {productList?.type === 1 ? "S" : "G"}
            </span>
            <button
              className={
                "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "
              }
              onClick={() => {
                window.open(`/product/${productList?.id}`, "_blank");
              }}
            >
              <button type="button" className="w-[20px] h-[20px]" onClick={(e) => {
                e.stopPropagation();
                onFilterApply(
                  {
                    ...pageData?.filters?.filters,
                    assigned: [
                      productList?.brand?.assigned.id,
                    ],
                  },
                  {
                    ...pageData?.boundFilters,
                    assigned: {
                      value:
                        productList?.brand?.assigned?.id,
                      label:
                        productList?.brand?.assigned?.name,
                    },
                  }
                );
              }}>
                    <img
                      alt="user"
                      src={
                        productList?.brand?.assigned?.profile_image ||
                        productList?.brand?.assigned?.image_url ||
                        "/assets/images/defaultAvatar.png"
                      }
                      className={"w-[20px] h-[20px] rounded-full object-cover"}
                                  />
                  </button>
              {productList?.apid}
            </button>
              <CopyText
                                  className={"text-[12px]"}
                                  text={productList?.apid}
                                  hint={false}
                                />
            {descriptiveContent(
          <HiFilter
          onClick={(e) => {
            if (!productList) return;
            e.stopPropagation();
            onFilterApply(
              {
                product_ids: [productList?.id],
              },
              {
                product_ids: [
                  {
                    label: productList?.title,
                    value: productList?.id,
                  },
                ],
              }
            );
          }}
            className={` w-3 h-3  cursor-pointer text-gray-300 hover:text-gray-500`}
          />,
          "Filter by Product"
        )}
          </div>
        ) : (
          <div className="flex items-center gap-[2px] !text-[12px] ">
            <ConnectProductModal
              key={offer?.id}
              toggle={() => (
                <div className={"flex flex-row gap-[2px]"}>
                  <span className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                    <Link className={"w-[11px] h-[11px]"} />
                  </span>
                  <div
                    className={
                      "text-red-700 hover:text-red-800 cursor-pointer "
                    }
                  >
                    Not Connected
                  </div>
                </div>
              )}
              mapListings={mapListings}
              salesChannelItem={SalesChannelItem}
              defaultListing={offer}
              callBack={(data) => {
                return dispatch(
                  connectProduct({
                    offerId: offer?.id,
                    productId: data?.id,
                  })
                ).then((result) => {
                  //handleFetchAndUpdateItem(data._data.order_id, data.id, result?.payload?.data)
                  if (result?.payload?.type === "success") {
                    dispatch(fetchOffer(offer?.id)).then((rs) => {
                      dispatch(
                        updateRow({
                          path: `offers.[id:${offer?.id}]`,
                          value: rs.payload.data,
                        })
                      );
                      dispatch(
                        updateRow({
                          path: `offers.[id:${offer?.id}]._updatedRow`,
                          value: true,
                        })
                      );
                      util.notifier({
                        type: "success",
                        message: result?.payload?.data,
                        errors: result?.payload?.errors,
                      });
                    });
                    return true;
                  }
                });
              }}
            />
          </div>
        )}

        {/* } */}
      </div>
    </div>
  );
};
Item.propTypes = {
  product: PropTypes.array.isRequired,
  offer: PropTypes.object,
  onFilterApply: PropTypes.func,
  pageData: PropTypes.object,
};

export default Item;

import {createShipment} from "../../../../store/orders"
import {useDispatch} from "react-redux";
import notifier from "../../../dynamic-page/util/notifier";


const useCreateShipmentInitializer = () => {
    const dispatch = useDispatch();

    const initializeShipment = async (carrier, callBack) => {
        dispatch(createShipment(carrier)).then((rs) => {
            notifier(rs?.payload?.data || rs?.payload)
            callBack(rs?.payload?.data);
            carrier.callBack();
        })

    };

    return {initializeShipment};
};

export default useCreateShipmentInitializer;

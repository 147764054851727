import * as Yup from "yup";
import React from "react";
import UI from "../../dynamic-form/elements";
import ProductImage from "../../dynamic-form/components/productImage";
import PropTypes from "prop-types";

const formView = (item) => {
  const innerSubmit = item?.id
    ? [
        {
          label: "Save Changes",
          type: "primary",
          action: "save",
          className: "!w-[134px]",
        },
      ]
    : [
        {
          label: "Create ",
          type: "primary",
          action: "saveAndOpen",
        },
      ];

  return {
    title: item?.id ? "Edit Product" : "Create Product",
    submit: innerSubmit,
    close: !item?.id,
    cancel: true,
    viewModel: (formDefaultValue) => {
      return [
        {
          name: "type",
          type: "Module",
          module: ProductTypeModule,
          disabled: !!item?.id,
          defaultValue: formDefaultValue?.type || 1,
          className: "col-span-2 ",
        },
        {
          name: "title",
          label: "Name",
          placeholder: "ex. Omron M2 Basic Blood Preassure Monitor",
          type: "Input",
          defaultValue: formDefaultValue?.title || null,
          validation: Yup.string().required("Product Name is required"),
          className: "col-span-2",
        },
        {
          name: "brand_id",
          label: "Brand",
          placeholder: "Select the product brand",
          api: {
            url: "/api/v1/brands",
            query: "?filter[name]={query}",
            optionValue: (item) => ({
              value: item.id,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
            }),
          },
          addNew: (formData) => ({
            api: "brands",
            data: (value) => ({ name: value }),
          }),
          style: {
            menu: {
              whiteSpace: "wrap !important ",
              maxWidth: "632px",
              zIndex: 9999,
            },
            option: {
              whiteSpace: "wrap !important ",
              padding: "4px 8px !important",
            },
          },
          className: "col-span-2 max-w-[632px]",
          type: "Select",
          defaultValue: formDefaultValue?.brand_id || null,
          validation: Yup.string().required("Brand is required"),
        },
        {
          name: "category_id",
          label: "Category",
          placeholder: "Select the product category",
          type: "Select",
          api: {
            url: "/api/v1/category" + "?filter[status]=active",
            view: "/api/v1/category",
            query: "&filter[name]={query}",
            optionValue: (item) => ({
              value: item.id,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
            }),
          },
          optional: true,
          defaultValue:
            formDefaultValue?.category?.id ||
            formDefaultValue?.category_id ||
            null,
          className: "col-span-2 max-w-[632px]",
        },
        {
          name: "image",
          label: "Image",
          placeholder:
            "Select a Single Product to add more to the Grouped Product",
          type: "Module",
          module: ProductImage,
          className: "col-span-2",
          labelClassName: "!leading-6",
          defaultValue: formDefaultValue?.image || null,
          productId: item?.id,
        },
        {
          name: "products",
          type: "Module",
          label: "Components",
          module: UI.ProductList,
          itemId: item?.id,
          defaultValue:
            item?.child_products?.map((item) => ({
              product: item,
              id: item.id,
              quantity: item.quantity,
            })) ||
            formDefaultValue?.products ||
            [],
          className: "col-span-2",
          labelClassName: "hidden",
          placeholder: "Select a single product to add as component",
          condition: (data) => data?.type === 2,
        },
        {
          name: "unit_id",
          defaultValue: 1,
          type: "Hidden",
        },
      ];
    },
  };
};
export default formView;

const ProductTypeModule = ({ item, change, data }) => {
  return (
    <div className="flex gap-4  items-center w-[632px] ">
      <button
        onClick={() => {
          if (!item?.disabled) change(1, "type");
        }}
        className="flex relative gap-3.5 cursor-pointer items-center p-4 flex-1 rounded-[8px] drop-shadow-lg bg-white"
      >
        <input
          type="radio"
          name="type"
          value={1}
          disabled={true}
          checked={data?.type === 1}
          // if disabled change the color of the radio button to gray
          className={`absolute top-4 right-4 text-[25px] ${
            item?.disabled ? "text-gray-500" : ""
          }`}
        />

        <div className="flex flex-col gap-3">
          <span className="flex gap-1 text-[18px] leading-[18px] items-center font-semibold text-gray-900  tracking-wide	">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4 0H1.6C1.17565 0 0.768687 0.168571 0.468629 0.468629C0.168571 0.768687 0 1.17565 0 1.6V10.4C0 10.8243 0.168571 11.2313 0.468629 11.5314C0.768687 11.8314 1.17565 12 1.6 12H7.2V14.4H4.8C4.58783 14.4 4.38434 14.4843 4.23431 14.6343C4.08429 14.7843 4 14.9878 4 15.2C4 15.4122 4.08429 15.6157 4.23431 15.7657C4.38434 15.9157 4.58783 16 4.8 16H11.2C11.4122 16 11.6157 15.9157 11.7657 15.7657C11.9157 15.6157 12 15.4122 12 15.2C12 14.9878 11.9157 14.7843 11.7657 14.6343C11.6157 14.4843 11.4122 14.4 11.2 14.4H8.8V12H14.4C14.8243 12 15.2313 11.8314 15.5314 11.5314C15.8314 11.2313 16 10.8243 16 10.4V1.6C16 1.17565 15.8314 0.768687 15.5314 0.468629C15.2313 0.168571 14.8243 0 14.4 0ZM14.4 1.6V7.2H1.6V1.6H14.4ZM1.6 10.4V8.8H14.4V10.4H1.6Z"
                fill="#1F2A37"
              />
            </svg>
            Single Product
          </span>
          <span className="text-[14px] leading-[17.5px] text-gray-500 font-normal tracking-wide">
            Can be supplied individually, with its own inventory and cost
            tracking.
          </span>
        </div>
      </button>
      <button
        onClick={() => {
          if (!item?.disabled) change(2, "type");
        }}
        className="flex relative cursor-pointer gap-3.5 items-center p-4 flex-1 rounded-[8px] drop-shadow-lg bg-white"
      >
        <input
          type="radio"
          name="type"
          value={2}
          checked={data?.type === 2}
          className={`absolute top-4 right-4 text-[25px] ${
            item?.disabled ? "text-gray-500" : ""
          }`}
        />

        <div className="flex flex-col gap-3">
          <span className="flex gap-1  text-[18px] leading-[18px] items-center font-semibold text-gray-900 tracking-wide">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_32987_42510)">
                <path
                  d="M5.6 11.2C5.6 10.7584 5.2416 10.4 4.8 10.4H1.6V8.8H4.8C5.2416 8.8 5.6 8.4416 5.6 8C5.6 7.5584 5.2416 7.2 4.8 7.2H1.6V1.6H12.8V2.4C12.8 2.8416 13.1584 3.2 13.6 3.2C14.0416 3.2 14.4 2.8416 14.4 2.4V1.6C14.4 0.7176 13.6824 0 12.8 0H1.6C0.7176 0 0 0.7176 0 1.6V10.4C0 11.2824 0.7176 12 1.6 12H4.8C5.2416 12 5.6 11.6416 5.6 11.2Z"
                  fill="#1F2A37"
                />
                <path
                  d="M14.4 4H8C7.1176 4 6.4 4.7176 6.4 5.6V14.4H4C3.5584 14.4 3.2 14.7584 3.2 15.2C3.2 15.6416 3.5584 16 4 16H15.2C15.6416 16 16 15.6416 16 15.2V5.6C16 4.7176 15.2824 4 14.4 4ZM14.4 14.4H8V5.6H14.4V14.4Z"
                  fill="#1F2A37"
                />
                <path
                  d="M11.2 13.6C12.5232 13.6 13.6 12.5232 13.6 11.2C13.6 9.8768 12.5232 8.8 11.2 8.8C9.8768 8.8 8.8 9.8768 8.8 11.2C8.8 12.5232 9.8768 13.6 11.2 13.6ZM11.2 10.4C11.6408 10.4 12 10.7592 12 11.2C12 11.6408 11.6408 12 11.2 12C10.7592 12 10.4 11.6408 10.4 11.2C10.4 10.7592 10.7592 10.4 11.2 10.4Z"
                  fill="#1F2A37"
                />
                <path
                  d="M11.2 8C11.6418 8 12 7.64183 12 7.2C12 6.75817 11.6418 6.4 11.2 6.4C10.7582 6.4 10.4 6.75817 10.4 7.2C10.4 7.64183 10.7582 8 11.2 8Z"
                  fill="#1F2A37"
                />
              </g>
              <defs>
                <clipPath id="clip0_32987_42510">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Grouped Product
          </span>
          <span className="text-[14px] leading-[17.5px] text-gray-500 font-normal tracking-wide">
            Bundles or packs of single products, has virtual inventory and cost.
          </span>
        </div>
      </button>
    </div>
  );
};

ProductTypeModule.propTypes = {
  item: PropTypes.shape({
    disabled: PropTypes.bool, // Adjust based on the actual structure of `item`
  }),
  change: PropTypes.func.isRequired, // `change` must be a function and is required
  data: PropTypes.shape({
    type: PropTypes.number.isRequired, // Assuming `type` is a number and required
  }).isRequired,
};
